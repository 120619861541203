import {
  userConstants
} from '../_constants';

export default function users(state = {}, action) {
  switch (action.type) {
    


    


    
    

    case userConstants.CREDIT_REF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREDIT_REF_SUCCESS:
      return {
        ...state,
        loading: false,
        reflist:action?.user
      };
    case userConstants.CREDIT_REF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
   
      };
      
    case userConstants.DELETE_BETS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_BETS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.DELETE_BETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
   
      };
      
      
      
   
   
   
   
   
      case userConstants.CREDIT_REFEFENCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREDIT_REFEFENCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.CREDIT_REFEFENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DOUBLE_AMOUNT_S_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DOUBLE_AMOUNT_S_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.DOUBLE_AMOUNT_S_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.BLOCK_NUMBERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.BLOCK_NUMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.BLOCK_NUMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.PASSWORD_UP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PASSWORD_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.PASSWORD_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.PASSWORD_UPDATED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PASSWORD_UPDATED_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.PASSWORD_UPDATED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.BLOCKED_GAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.BLOCKED_GAME_SUCCESS:
      return {
        ...state,
       blockedGame:action,
        loading: false,
      };
    case userConstants.BLOCKED_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
   
    case userConstants.GET_USER_OF_MASTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_OF_MASTER_SUCCESS:
      return {
        ...state,
        adminUsersList:action?.user?.data?.results,
        adminUsersTotal:action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_USER_OF_MASTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
   
   
   
   
   
      case userConstants.GET_TOTAL_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        totalCounts:action?.user,
        loading: false,
      };
    case userConstants.GET_TOTAL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.CLEAR_IMAGE:
      return {
        ...state,
        fileData: null,
      };





    case userConstants.GET_LOGIN_HISTORY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LOGIN_HISTORY_ID_SUCCESS:
      return {
        ...state,
        LoginSinglUSerList: action?.user?.data?.list,
        LoginSinglUSertotal: action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_LOGIN_HISTORY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_TXN_LIST_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TXN_LIST_USERID_SUCCESS:
      return {
        ...state,
        tnxList: action?.user?.data?.list,
        tnxTotal: action?.user?.data?.total,
        loading: false,
      };
    case userConstants.GET_TXN_LIST_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_BET_LIST_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_BET_LIST_USERID_SUCCESS:
      return {
        ...state,
        betsListtotal: action?.user?.data?.total,
        betsListdata: action?.user?.data?.list,
        loading: false,
      };
    case userConstants.GET_BET_LIST_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case userConstants.UPOLAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPOLAD_IMAGE_SUCCESS:
      return {
        ...state,
        fileData: action?.response?.data?.imageURL,
        loading: false,
      };
    case userConstants.UPOLAD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_CREATE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_CREATE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
      };
    case userConstants.USER_CREATE_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_BY_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_BY_USERID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        userDetailsById: action?.user?.data,
        loading: false,
      };
    case userConstants.GET_USER_BY_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        userList: action.user.data.list,
        userTotal: action.user.data.total,
        loading: false,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    




      
      case userConstants.GET_USER_LIST_REQUEST2:
        return {
          ...state,
          loading: true
        };
      case userConstants.GET_USER_LIST_SUCCESS2:
        return {
          ...state,
          addUserSuccess: false,
          userList2: action.user.data.list,
          userTotal2: action.user.data.total,
          loading: false,
        };
      case userConstants.GET_USER_LIST_FAILURE2:
        return {
          ...state,
          loading: false,
          error: action.error
        };
      

    
    
      case userConstants.GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        adminProfileDetails: action.user.data,
        loading: false,
      };
    case userConstants.GET_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}