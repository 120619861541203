import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions } from '../../_actions';
import { MdOutlineNavigateNext } from "react-icons/md";
import { useParams } from 'react-router-dom';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { useTime } from 'react-timer-hook'


const PlaceBet = () => {

  const dispatch = useDispatch();
  const { games } = useSelector(state => state) || {};
  const { allMarket, gamesList, sheetLockList, sheetById } = games || {};

  const [arryofbets, setArryofbets] = useState([]);
  const [arryofbetsSheetLock, setArryofbetsSheetLock] = useState([]);
  const [isCurrentSheetLockDetails, setCurrentSheetLockDetails] = useState({});
  const { id, name } = useParams();
  let [localStoragedata, setlocalStoragedata] = useState();
  let [isDisable, setIsDisable] = useState(false);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [userName, setUserName] = useState({});
  const [gameName, setGameName] = useState({});
  const { subAdminData, betsByUserId } = useSelector(state => state.games);
  const [isBetPrime, setIsBetPrime] = useState(false);
  const [gameNameRes, setGameNameRes] = useState(null);
  const [stateKey, setStateKey] = useState(null);
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)


  const userPalyerName = games?.betsByUserId && games?.betsByUserId?.[0]?.userId && games?.betsByUserId?.[0]?.userId?.userName ? games?.betsByUserId?.[0]?.userId?.userName : userName && userName?.userName ? userName?.userName : null;

  const handleKeyDown1 = (event) => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime((prevState) => !prevState); // Toggle the status
    }
  };

  const [enterPressCount, setEnterPressCount] = useState(0); // Track Enter presses
  const betNumberInputRef = useRef(null);
  const amountInputRef = useRef(null); // Reference for the amount input
  const scrollContainerRef = useRef(null);

  // useEffect to ensure scrolling happens after component renders
  useEffect(() => {
    // Timeout to ensure scrolling happens after DOM update
    if (scrollContainerRef.current) {
      console.log("Scrolling to bottom...");
      setTimeout(() => {
        // Scroll to the bottom of the container
        scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
      }, 100); // Adjust the delay if necessary
    }
    const totalAmountRes = (betsByUserId && betsByUserId.length > 0)
      ? betsByUserId.reduce((sum, record) => {
        if (['Single Open', 'Single Close'].includes(record?.localmarketname)) {
          return sum + (Number(record.amount) * 10) || 0;
        }
        if (['Single Kenchi', 'Double Kenchi'].includes(record?.localmarketname)) {
          return sum + (Number(record.amount) * record?.packet?.length) || 0;
        }
        return sum + Number(record.amount) || 0;
      }, 0)
      : 0;
    setTotalAmount(totalAmountRes)
  }, [betsByUserId]);

  const handleKeyDown = async (e, fieldName) => {
    console.log('fieldName', enterPressCount, fieldName, e.key);

    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter behavior

      if (['F9'].includes(stateKey) && enterPressCount === 0 && fieldName === 'betNumber') {
        console.log('222222222222222222');
        amountInputRef.current?.focus();
        setEnterPressCount((prevCount) => prevCount + 1);
      }

      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        console.log('3333333333333333');
        await handleSubmit();
        setEnterPressCount(0);
        betNumberInputRef.current?.focus();
        return;
      }

      if (enterPressCount === 0 && fieldName === 'betNumber') {
        console.log('44444444444444444');
        amountInputRef.current?.focus();
        setEnterPressCount((prevCount) => prevCount + 1);
      }
      if (enterPressCount === 1) {
        console.log('5555555555555555555555');
        await handleSubmit();
        setEnterPressCount(0);
        betNumberInputRef.current?.focus();
        return; // Exit early to avoid incrementing the count
      }
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    try {
      const { betNumber, amount } = formData;

      if (errors.amount || errors.betNumber) {
        console.log("Please fix validation errors");
        return;
      }

      let packet = [];
      let isOpen = false;
      let marketId = currentOption

      console.log('marketIdmarketIdmarketIdmarketId', marketId);

      if (['F9'].includes(stateKey)) {

        const resData = [{ ...formData, isOpen: isOpen, marketId, games: currentOption2, localmarketname: 'Jodi', isBetPrime: isBetPrime }]
        const gameId = fieldsGame && gameName["_id"] ? gameName["_id"] : id

        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null
        }

        const JodiRes = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
        for (const element of JodiRes) {
          // Map the bets for the current element
          const formattedBets = resData.map((games) => {
            return {
              userId: fieldsGame?.userId || null,
              betNumber: element,
              amount: games?.amount,
              marketId: games?.marketId?._id,
              gameId: gameId,
              isBetPrime: games?.isBetPrime,
              isOpen: games?.isOpen,
              localmarketname: 'Jodi',
              packet: [element],
            };
          });

          await dispatch(gamesActions.placeBetByAdminRes(formattedBets, res));
        }

        setStateKey(null)
        setIsBetPrime(false);
        setFormData(prevData => ({
          ...prevData,
          betNumber: '' // Reset only the betNumber field
        }));
      }
      if (marketId && currentOption2 && currentOption && betNumber && amount) {
        const marketData = (['Single Open'].includes(marketId?.toShow))
          ? (betNumber?.length === 1 ? 'Single Open' :
            betNumber?.length === 2 ? 'Jodi' : marketId?.toShow)
          : marketId?.toShow;
        console.log('marketDatamarketDatamarketDatamarketData', marketData);
        if ((marketData === "Single Close" || marketData === "Single Open") && betNumber?.length > 1) {
          return;
        }
        if (["Single Kenchi", "Double Kenchi"].includes(marketData) && (betNumber?.length < 2 || betNumber?.length > 7)) {
          return;
        }
        if (marketData === "Jodi" && betNumber?.length !== 2) {
          return;
        }
        if (marketData === "Single Open") {
          isOpen = true;
        }
        switch (marketData) {
          case "Single Open":
            packet = getSingleOpenCombinations(betNumber);
            break;
          case "Single Close":
            packet = getSingleCloseCombinations(betNumber);
            break;
          case "Jodi":
            packet = [betNumber];
            break;
          case "Single Kenchi":
            packet = getUniqueTwoDigitCombinations(betNumber);
            break;
          case "Double Kenchi":
            packet = getTwoDigitCombinations(betNumber);
            break;
          default:
            break;
        }

        const resData = [{ ...formData, packet, isOpen: isOpen, marketId, games: currentOption2, localmarketname: marketData, isBetPrime: isBetPrime }]

        const gameId = fieldsGame && gameName["_id"] ? gameName["_id"] : id

        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null
        }

        const formattedBets = resData.map((games, ...bet) => {
          const amount = ["Single Open", "Single Close"].includes(games?.localmarketname) ? (Number(games?.amount) / 10).toString() : games?.amount.toString()
          return ({
            userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null,
            betNumber: games?.betNumber,
            amount: amount,
            marketId: games?.marketId?._id,
            gameId: gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: games?.localmarketname,
            packet: games?.packet
          });
        })
        dispatch(gamesActions.placeBetByAdmin(formattedBets, res));
        setIsBetPrime(false);
        setFormData(prevData => ({
          ...prevData,
          betNumber: '' // Reset only the betNumber field
        }));
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
    }
  };

  useEffect(() => {

    document.addEventListener('keydown', handleKeyDown1);
    return () => {
      document.removeEventListener('keydown', handleKeyDown1);
    };


  }, []);

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown2);
      return () => {
        window.removeEventListener('keydown', handleKeyDown2);
      };
    }
    if (fieldsGame) {
      if (!isDisable) {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }
    }

  }, [options, isDisable, fieldsGame]);

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown3);
      return () => {
        window.removeEventListener('keydown', handleKeyDown3);
      };
    }
  }, [isDisable || fieldsGame]);

  useEffect(() => {
    setCurrentSheetLockDetails(sheetById ? sheetById : {});
    setArryofbetsSheetLock(sheetById?.bets || []);
  }, [sheetById]);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [currentIndex2, setCurrentIndex2] = useState(0);
  let [options2, setoptions2] = useState([])
  let currentOption2 = Array.isArray(options2) && options2.length > 0 ? options2[currentIndex2] : null;

  const [currentIndex, setCurrentIndex] = useState(0);
  let currentOption = Array.isArray(options) && options.length > 0 ? options[currentIndex] : null;

  useEffect(() => {

    if (name) {
      setGameNameRes(name)
    }

    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
    dispatch(gamesActions.getAllMarket());
    dispatch(gamesActions.getAllGames());
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      setIsDisable(true);
      dispatch(gamesActions.getSubAdminList())
    }
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(gamesActions.getPlaceBetsByUserId({ gameId: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (fieldsGame?.userId) {
      // setTimeout(() => {
      //   betNumberInputRef.current?.focus();
      // }, 10);
      dispatch(gamesActions.getPlaceBetsByUserId({
        gameId: gameName?._id,
        userId: fieldsGame?.userId
      }));
    }
  }, [fieldsGame, dispatch]);


  // Single Open: Unique 
  function getSingleOpenCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const combinations = [];

    for (let j = 0; j < digits.length; j++) {
      combinations.push(number + digits[j]);
    }
    return combinations;
  }
  // Single Close: Unique 
  function getSingleCloseCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const combinations = [];

    for (let j = 0; j < digits.length; j++) {
      combinations.push(digits[j] + number);
    }
    return combinations;
  }
  // Single Kachi: Unique Two-Digit Combinations
  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))];
    const combinations = [];

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j]);
        }
      }
    }
    return combinations;
  }

  // Double Kachi: All Two-Digit Combinations
  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))];
    const combinations = [];

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j]);
      }
    }
    return combinations;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validate(name, value);

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }));

    if (name === 'betNumber') {
      setEnterPressCount(0)
    }
    if (name === 'amount') {
      setEnterPressCount(1)
    }
  };

  const validate = (name, value) => {
    let error = '';

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.';
      }
    } else if (name === 'betNumber') {
      const marketData = currentOption?.toShow;

      if ((marketData === 'Single Close') && value.length > 1) {
        error = 'Only single digit allowed for Single Close/Open';
      } else if (['Single Kenchi', 'Double Kenchi'].includes(marketData) && (value.length < 3 || value.length > 7)) {
        error = 'Enter 3-7 digits for Kanchee types';
      } else if (['Jodi', 'Single Open'].includes(marketData) && value.length < 1 && value.length > 2) {
        error = 'Jodi requires exactly 2 digits';
      }
    }

    return error;
  };

  const handleDelete = (data) => {
    console.log('data', data);
    const res = {
      gameId: data?.gameId?._id ?? null,
      userId: data?.userId._id ?? null
    }
    dispatch(gamesActions.placeBetByFreeze({ id: data._id }, res));
  };


  let singleId = allMarket && allMarket?.length > 0 && allMarket.find(e => e?.shortName === "SINGLEDIGIT")
  let JodiId = allMarket && allMarket?.length > 0 && allMarket.find(e => e?.shortName === "JODI")

  useEffect(() => {

    const currentTime =
      moment().hours() * 3600 + moment().minutes() * 60 + moment().seconds();
    const availableGames = gamesList?.filter(
      (game) => currentTime >= game?.openTime && currentTime <= game?.closingTime && !game.isGameClose
    );

    if (availableGames?.length > 0) {
      console.log('');
      setGameNameRes(availableGames?.[0]?.name)
      setGameName(availableGames?.[0])
    }

    let data

    console.log('gameNameResgameNameRes', availableGames?.[0]?.name);

    if (['akc.close'].includes(availableGames?.[0]?.name) && !['F9'].includes(stateKey)) {
      data = [{ ...JodiId, toShow: 'Single Close' }
      ].filter(item => item !== null);
    } else if (['ak open'].includes(availableGames?.[0]?.name) && !['F9'].includes(stateKey)) {
      data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null);
    } else if (['F9'].includes(stateKey)) {
      data = [
        { ...JodiId, toShow: 'Jodi' }
      ].filter(item => item !== null);
    } else {
      data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Single Close' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null);
    }
    setoptions(data)
    setoptions2(gamesList)



  }, [stateKey, fieldsGame, gameNameRes, singleId, JodiId, gamesList, games])

  const handleNext = (e) => {
    e.preventDefault()
    console.log('options', options);
    setCurrentIndex((prevIndex) => (prevIndex === options?.length - 1 ? 0 : prevIndex + 1));
  };

  const handleKeyDown2 = (event) => {
    const number = options?.length - 1;

    if (event.key === "ArrowUp") {
      setCurrentIndex((prevIndex) => {
        // Up arrow: Only allow moving to 'Single Kenchi'
        const singleKenchiIndex = options.findIndex(item => item.toShow === 'Single Kenchi');
        return singleKenchiIndex >= 0 ? singleKenchiIndex : prevIndex;
      });
    }

    if (event.key === "ArrowRight") {
      setCurrentIndex((prevIndex) => {
        // Right arrow: Only allow moving to 'Single Close'
        const singleCloseIndex = options.findIndex(item => item.toShow === 'Single Close');
        return singleCloseIndex >= 0 ? singleCloseIndex : prevIndex;
      });
    }

    if (event.key === "ArrowLeft") {
      setCurrentIndex((prevIndex) => {
        // Left arrow: Only allow moving to 'Single Open'
        const singleOpenIndex = options.findIndex(item => item.toShow === 'Single Open');
        return singleOpenIndex >= 0 ? singleOpenIndex : prevIndex;
      });
    }

    if (event.key === "ArrowDown") {
      setCurrentIndex((prevIndex) => {
        // Down arrow: Only allow moving to 'Double Kenchi'
        const doubleKenchiIndex = options.findIndex(item => item.toShow === 'Double Kenchi');
        return doubleKenchiIndex >= 0 ? doubleKenchiIndex : prevIndex;
      });
    }
  };

  const handleKeyDown3 = (event) => {
    if (["F9"].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
    }
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    setStateKey(null)
    const { name, value } = e.target;
    console.log(' name, value', name, value);
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));


    setArryofbets([]);


    if (name === "userId" && gameNameRes) {
      console.log('11111111111111');
      setTimeout(() => {
        betNumberInputRef.current?.focus();
      }, 10);
      console.log('11111111122222222222322222222222254456');
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
      setIsDisable(false)

    }

  };

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' });

  return (

    <div className="w-full bg-white h-screen overflow-hidden overflow-y-auto">
      <div className="relative">
        <div className="justify-between flex flex-col bg-white rounded-md border">
          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className="text-left bg-white space-y-2 rounded-md w-full p-4">
              <div className='flex justify-between md:flex-nowrap gap-1 flex-wrap items-end w-full'>


                <div className="w-full">
                  <label className="block text-gray-700 text-sm mb-1" htmlFor="userId">User Name :</label>
                  <select
                    className="w-56 px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none"
                    id="userId"
                    name="userId"
                    value={fieldsGame?.userId || ""}
                    onChange={(e) => inputChangeCreate(e)}
                  >
                    <option value="">Select User Name</option>
                    {subAdminData && subAdminData.length > 0
                      ? subAdminData.map((element, index) => (
                        <option key={index} value={element?._id || ""}>
                          {element?.userName || ""}
                        </option>
                      ))
                      : null}
                  </select>
                  {errorsGame?.userId && (
                    <div className="text-red-600 invalid-feedback">{errorsGame["userId"]}</div>
                  )}
                </div>

              </div>
            </div>
          )}

          {/* isSheetLock table */}
          <div className="bg-gray-50 w-full px-4 pb-8">
            <div className='text-black capitalize text-xl w-full flex justify-between px-4 pt-3 pb-2'>
              <p>Bet Table</p>
              <p className='text-base'>Game Name-: <span className='text-xl font-semibold'>{gameNameRes}</span></p>
              <p className='text-base'>User Name-: <span className='text-xl font-semibold'>{userPalyerName}</span></p>
              <p className='text-base'>Total Amount-: <span className='text-xl font-semibold'>{totalAmount ?? 0}</span></p>
              <p className='text-base flex justify-between items-center space-x-2'>Timer-:
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>{hours}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>{minutes}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>{seconds}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md uppercase space-x-2'>{ampm}</span>
              </p>
            </div>

            <div className='h-[33rem] overflow-x-auto overflow-y-auto' ref={scrollContainerRef}>
              <table className="min-w-full border border-gray-200">
                <thead className="sticky top-0 bg-gray-200 z-10">
                  <tr className="text-black font-semibold text-base">
                    <th className="p-2 uppercase tracking-wide border border-gray-300">#</th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className="p-2 uppercase tracking-wide border border-gray-300">User</th>
                        <th className="p-2 uppercase tracking-wide border border-gray-300">Game</th>
                      </>
                    )}
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Number</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Amount</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Market</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Packet</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Date/Time</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {betsByUserId && betsByUserId.length > 0 ? (
                    betsByUserId.map((bet, index) => (
                      <tr key={index} className="border text-2xl last:border-none last:bg-green-300 bg-white text-gray-700">
                        <td className="p-2 border border-gray-200 text-center">{index + 1}</td>
                        {localStoragedata && localStoragedata?.roleId === 0 && (
                          <>
                            <td className="p-2 border border-gray-200 text-center">{bet?.userId?.userName ?? ''}</td>
                            <td className="p-2 font-medium border border-gray-200 text-center">{bet?.gameId?.name ?? ''}</td>
                          </>
                        )}
                        {bet.isBetPrime ? (
                          <>
                            <td className="p-2 text-red-600 border bordered-500 text-center font-bold">{bet.betNumber}</td>
                            <td className="p-2 text-red-600 border bordered-500 text-center font-bold">
                              {['Single Open', 'Single Close'].includes(bet?.localmarketname) ? bet?.amount * 10 : bet?.amount}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="p-2 border border-gray-200 text-center font-bold">{bet.betNumber}</td>
                            <td className="p-2 border border-gray-200 text-center font-bold">
                              {['Single Open', 'Single Close'].includes(bet?.localmarketname) ? bet?.amount * 10 : bet?.amount}
                            </td>
                          </>
                        )}
                        <td className="p-2 border border-gray-200 text-center">{bet?.localmarketname}</td>
                        <td className="p-2 border border-gray-200 text-center">
                          {['Single Open', 'Single Close'].includes(bet?.marketId?.name) ? bet?.packet?.length * 10 : bet?.packet?.length}
                        </td>
                        <td className="p-2 border border-gray-200 text-center">{moment(bet?.createdAt).format('DD-MM-YYYY HH:mm')}</td>
                        <td className="p-2 text-center">
                          <button
                            onClick={() => handleDelete(bet)}
                            className="text-red-500 hover:text-red-600 font-semibold text-base"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='border border-gray-300'>
                      <td colSpan="8" className="text-center px-4 py-2 text-base text-gray-500 border border-gray-300">
                        No bets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <form disabled={isDisable} className="p-4 bg-gray-100 rounded-t-lg shadow-md space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row lg:justify-between lg:items-center w-full z-10 flex flex-col justify-between items-center rounded-lg">
            <div className="flex flex-col lg:flex-row items-center gap-4 w-full">
              <div className="flex items-center gap-2">
                <div className="text-gray-800 border border-gray-300 rounded-lg px-4 py-2 w-full text-center">
                  {currentOption?.toShow}
                </div>
                <button
                  onClick={(e) => handleNext(e)}
                  disabled={isDisable}
                  type="button" // Set button to avoid form submission
                  className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-lg transition duration-300"
                >
                  <MdOutlineNavigateNext />
                </button>
              </div>

              {/* Bet Number Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="number"
                  ref={betNumberInputRef} // Reference for shifting focus back
                  value={['F9'].includes(stateKey) ? '11' : formData && formData.betNumber ? formData.betNumber : ''}
                  onChange={(e) => {
                    handleChange(e); // Call your existing handleChange function
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'betNumber')}
                  disabled={isDisable}
                  name="betNumber"
                  className="bg-white border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-100 text-gray-700 text-sm rounded-lg block w-full p-2.5 transition duration-300"
                  placeholder="Enter a number"
                  required
                />
                {errors.betNumber && <span className="text-red-500 text-xs mt-1">{errors.betNumber}</span>}
              </div>

              {/* Amount Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="number"
                  ref={amountInputRef} // Reference for shifting focus
                  value={formData && formData.amount ? formData.amount : ''}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  disabled={isDisable}
                  name="amount"
                  className="bg-white border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-100 text-gray-700 text-sm rounded-lg block w-full p-2.5 transition duration-300"
                  placeholder="Enter an amount"
                  required
                />
                {errors.amount && <span className="text-red-500 text-xs mt-1">{errors.amount}</span>}
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default PlaceBet;