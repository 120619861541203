import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions, karachiGamesActions, reportActions } from '../../_actions';
import Loader from "../../components/Loader/Loader";
import moment from 'moment';

const KarachiProfitLoss = () => {
  const dispatch = useDispatch();
  const { games, reports } = useSelector(state => state) || {};
  let { allMarket, allUserList } = games ? games : {};

  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});

  const today = moment().format('YYYY-MM-DD');
  console.log('today', today);


  const [keyWord, setkeyWord] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const { subAdminData, karachiGame } = useSelector(state => state.games);
  console.log('subAdminData', subAdminData);
  // const { karachiGamesList, karachiPlaceBet } = karachiGame || {};

  useEffect(() => {
    dispatch(karachiGamesActions.getAllKarachiGames())
    dispatch(gamesActions.getSubAdminList())
  }, []);


  console.log('allMarket', allMarket);


  const createGameSubmit = async (e) => {


    e.preventDefault();
    if (handleValidationCreateGame()) {
      const yesterday = moment(fieldsGame?.date ?? today).subtract(1, 'days').format('YYYY-MM-DD');
      console.log('today', today);
      console.log('yesterday', yesterday);

      let game = {
        "date": fieldsGame?.date ?? today,
        "userId": fieldsGame?.userId,
      }
      let game1 = {
        "date": yesterday,
        "userId": fieldsGame?.userId,
      }

      dispatch(reportActions.getKarachiGamePaymentsForSubAdmin(game));
      dispatch(reportActions.getKarachiGamePaymentsForSubAdmin1(game1));
    }
  };
  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select Game Name";
    }



    setErrorsGame(errors);
    return formIsValid;
  };


  const inputChangeCreate = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));

    console.log('e___________________', name, value);
    console.log('e___________________', subAdminData);

    if (name === 'userId') {
      console.log('e___________________1', name, value);
      const details = subAdminData.find(ele => ele._id === value)
      setUserDetails(details)
      console.log('userDetails ', userDetails);
    }

  };

  let { getKarachiGamePayments, getKarachiGamePayments1, loading } = reports ? reports : []

  console.log('getKarachiGamePayments', getKarachiGamePayments);

  // Ensure getKarachiGamePayments is an array, or use an empty array as a fallback
  const totalBetsAmountSum1 = (getKarachiGamePayments1 || []).reduce((sum, record) => sum + record.totalBetsAmount, 0);
  const totalSingleCloseAmountSum1 = (getKarachiGamePayments1 || []).reduce((sum, record) => sum + record.totalSingleCloseAmount, 0);
  const totalSingleOpenAmountSum1 = (getKarachiGamePayments1 || []).reduce((sum, record) => sum + record.totalSingleOpenAmount, 0);
  const totalJodiAmountSum1 = (getKarachiGamePayments1 || []).reduce((sum, record) => sum + record.totalJodiAmount, 0);
  const commDetails1 = (Number(totalBetsAmountSum1 ?? 0) * Number(userDetails?.comm ?? 0)) / 100;


  console.log('totalBetsAmountSum1', totalBetsAmountSum1);
  console.log('totalSingleCloseAmountSum1', totalSingleCloseAmountSum1);
  console.log('totalSingleOpenAmountSum1', totalSingleOpenAmountSum1);
  console.log('totalJodiAmountSum1', totalJodiAmountSum1);



  const totalBetsAmountSum = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalBetsAmount, 0);
  console.log('totalBetsAmountSumtotalBetsAmountSumtotalBetsAmountSum', totalBetsAmountSum);
  const totalSingleCloseAmountSum = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalSingleCloseAmount, 0);
  const totalSingleOpenAmountSum = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalSingleOpenAmount, 0);
  const totalBetsOpenClose1Sum = Number(totalSingleCloseAmountSum) + Number(totalSingleOpenAmountSum);
  const totalJodiAmountSum = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalJodiAmount, 0);
  const totalRingAmount = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalRingAmount, 0);
  const totalWinAmount = (getKarachiGamePayments || []).reduce((sum, record) => sum + record.totalWinAmount, 0);
  // const totalRingAmount = Number(ele?.totalRingAmount ?? 0);
  const prizeDetail = Number(totalSingleCloseAmountSum ?? 0) + Number(totalSingleOpenAmountSum ?? 0);
  const commDetails = (Number(totalBetsAmountSum ?? 0) * Number(userDetails?.comm ?? 0)) / 100;
  const harPrize = (Number(totalBetsAmountSum ?? 0) - (Number(totalSingleCloseAmountSum ?? 0) * 9 + Number(totalSingleOpenAmountSum ?? 0) * 9 + Number(totalJodiAmountSum ?? 0) * 90 + commDetails));


  const installment = harPrize + (((Number(totalBetsAmountSum1 ?? 0) - (Number(totalSingleCloseAmountSum1 ?? 0) * 9 + Number(totalSingleOpenAmountSum1 ?? 0) * 9 + Number(totalJodiAmountSum1 ?? 0) * 90 + commDetails1))) * (100 - userDetails.share) / 100);



  const akraPrizeDetail = Number(totalJodiAmountSum ?? 0);
  console.log('installment', installment);






  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none bg-gray-50">

        <div className="relative z-20 flex min-h-screen">
          <div className="flex w-full overflow-hidden ">
            <div className="flex flex-col flex-1 overflow-hidden">
              <main className="relative flex-1">
                <div className="p-3 sm:p-5">
                  <div className="space-y-4">

                    <div className='flex justify-between sm:flex-nowrap gap-1 flex-wrap items-end w-full'>

                      <div className="w-full">
                        <label class="block text-gray-700 text-sm mb-1">Date</label>
                        <input className={`w-full px-3 py-1 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`} id="date" name="date" placeholder="Enter Date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} max={today} type="date" onChange={inputChangeCreate} />
                        {errorsGame && errorsGame["date"] ? (
                          <div className="text-red-600 text-sm invalid-feedback">
                            {errorsGame["date"]}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full">
                        <label class="block text-gray-700 text-sm mb-1" for="name">User Name :</label>
                        <select className="w-full px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none" id="userId" name="userId" placeholder="name" value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                          <option value="">Select User Name</option>
                          {subAdminData && subAdminData.length > 0 ? subAdminData.map((element, index) => (<option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}
                          </option>
                          )) : null}
                        </select>
                        {errorsGame && errorsGame["userId"] ?
                          <div className="text-red-600 invalid-feedback">{errorsGame["userId"]}</div> : null}
                      </div>

                      <div className="w-full">
                        <button className="w-full px-4 py-1 text-base font-semibold text-white capitalize transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                      </div>

                    </div>

                    {getKarachiGamePayments ?
                      <div class="bg-white shadow-md rounded-lg w-full p-4">
                        <div class="flex space-x-4 text-xl font-semibold border-b pb-2 mb-4">
                          <p className='capitalize'> Draw Detail of</p>
                          <p>{moment(fieldsGame?.date).format('DD-MM-YYYY') ?? moment(today).format('DD-MM-YYYY')}</p>
                          <p className='capitalize'> {userDetails?.userName ?? null}</p>
                        </div>

                        <div className="overflow-x-auto">
                          <table className="table-auto border-collapse border border-gray-300 w-full text-white text-xs">
                            <thead>
                              <tr className="bgcolor text-xl font-semibold">
                                <th className="border px-2 py-2">Mark</th>
                                <th className="border px-2 py-2">First Result</th>
                                <th className="border px-2 py-2">Second Result</th>
                                <th className="border px-2 py-2">Total Sale</th>
                                <th className="border px-2 py-2">Op. Cz. Ct.</th>
                                <th className="border px-2 py-2">Akara</th>
                                <th className="border px-2 py-2">Ring TND</th>
                                <th className="border px-2 py-2">Remaining</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getKarachiGamePayments && getKarachiGamePayments.length > 0 ? (
                                getKarachiGamePayments.map((ele, index) => {
                                  const totalOpenCloseAmount = Number(ele?.totalSingleCloseAmount ?? 0) + Number(ele?.totalSingleOpenAmount ?? 0) + Number(ele?.totalCenterAmount ?? 0);

                                  const totalSingleAmount = Number(ele?.totalSingleCloseAmount ?? 0) * 9 + Number(ele?.totalSingleOpenAmount ?? 0) * 9 + Number(ele?.totalJodiAmount ?? 0) * 90;
                                  const profitOrLoss = totalSingleAmount;

                                  return (
                                    <tr key={index} className="bg-[#FFE4B4] text-green-800 font-semibold text-xl text-center">
                                      <td className="border px-2 py-2">{ele?.gameName ?? null}</td>
                                      <td className="border px-2 py-2">{ele?.result?.[0] ?? null}</td>
                                      <td className="border px-2 py-2">{`${ele?.result?.[1]} ${ele?.result?.[2]} ${ele?.result?.[3]}` ?? null}</td>
                                      <td className="border px-2 py-2">{ele?.totalBetsAmount ?? 0}</td>
                                      <td className="border px-2 py-2 text-[#2D3D24]">{totalOpenCloseAmount}</td>
                                      <td className="border px-2 py-2 text-[#DC64A2]">{ele?.totalJodiAmount ?? 0}</td>
                                      <td className="border px-2 py-2 text-[#DC64A2]">{ele?.totalRingAmount ?? 0}</td>
                                      <td className="border px-2 py-2">{totalWinAmount}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-center px-2 py-2 font-semibold" colSpan="8">No Data Available</td>
                                </tr>
                              )}
                            </tbody>
                            <tfoot>
                              <tr className="bg-black text-white font-semibold text-xl">
                                <td className="border px-2 py-2 text-center" colSpan="3">Total</td>
                                <td className="border px-1 py-1 text-center">{totalBetsAmountSum}</td>
                                <td className="border px-1 py-1 text-center">{totalBetsOpenClose1Sum}</td>
                                <td className="border px-1 py-1 text-center">{totalJodiAmountSum}</td>
                                <td className="border px-1 py-1 text-center">{totalRingAmount}</td>
                                <td className="border px-1 py-1 text-center" colSpan="2"></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        <div class="bg-gray-100 p-4 mt-4 rounded-lg">
                          <div className='w-full border-b'>
                            <p class="text-center font-bold text-lg mb-2">SUMMARY</p>
                          </div>
                          <div class="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Total Sale</span>
                            <span className='font-semibold text-green-800'>{totalBetsAmountSum}</span>
                          </div>
                          <div class="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>O/C Prize Detail</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{prizeDetail} /</span>
                            <span className='font-semibold text-green-800'>{Number(prizeDetail ?? 0) * 9}</span>
                          </div>
                          <div class="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Akra Prize Detail</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{akraPrizeDetail} /</span>
                            <span className='font-semibold text-green-800'>{Number(akraPrizeDetail ?? 0) * 90}</span>
                          </div>
                          <div class="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Comm. Detail</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{userDetails?.karachiComm ?? 0} % /</span>
                            <span className='font-semibold text-green-800'> {commDetails}</span>
                          </div>
                          <div className="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Gross Amount</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{totalWinAmount > 0 ? `Har / ` : `Jeet /`}</span>
                            <span className='font-semibold text-green-800'>{totalWinAmount > 0 ? `${totalWinAmount}` : `${Math.abs(totalWinAmount)}`}</span>
                          </div>
                          <div className="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Less % Percent</span>
                            <span className='text-right text-[#2A0037] font-semibold'> {userDetails?.karachiShare ?? 0} % /{' '}</span>
                            <span className='font-semibold text-green-800'>
                              {Math.abs(totalWinAmount) && userDetails?.karachiShare
                                ? (Math.abs(totalWinAmount) * userDetails.karachiShare) / 100
                                : 0
                              }
                            </span>
                          </div>
                          <div className="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Final % Net</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{100 - (userDetails?.karachiShare ?? 0)} % /{' '}</span>
                            <span className='font-semibold text-green-800'>
                              {Math.abs(totalWinAmount) && userDetails?.karachiShare
                                ? ((Math.abs(totalWinAmount) * (100 - userDetails.karachiShare)) / 100)?.toFixed(2)
                                : 0
                              }
                            </span>
                          </div>
                          <div className="flex justify-between text-base border-b py-1">
                            <span className='textcolor font-semibold'>Installment</span>
                            <span className='font-semibold text-green-800'>
                              {installment?.toFixed(2) ?? 0}
                            </span>
                          </div>
                          <div class="flex justify-between text-base py-1">
                            <span className='textcolor font-semibold'>Final Amount</span>
                            <span className='font-semibold text-green-800'>
                              {Math.abs(totalWinAmount) && userDetails?.karachiShare
                                ? ((Math.abs(totalWinAmount) * (100 - userDetails.karachiShare)) / 100 + installment)?.toFixed(2)
                                : 0
                              }
                            </span>
                          </div>
                        </div>
                      </div> :
                      null}

                  </div>
                </div>
              </main>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default KarachiProfitLoss;
