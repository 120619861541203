import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions, reportActions } from '../../_actions';
import moment from 'moment';
import { FiMinimize } from "react-icons/fi";
import { CgMaximize } from "react-icons/cg";


const Karachi = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const { games, reports } = useSelector(state => state) || {};
  let { allMarket, allUserList } = games ? games : {};

  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [gameName, setGameName] = useState({});
  let { karachiResultDataNum, resultDataNum, isPopup } = reports ? reports : []
  console.log('karachiResultDataNumkarachiResultDataNumkarachiResultDataNum', karachiResultDataNum);
  const today = moment().format('YYYY-MM-DD');
  console.log('today', today);


  const [keyWord, setkeyWord] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const { gamesList, gamesTotal } = useSelector(state => state.games);
  const [sums, setSums] = useState({
    singleSum: 0,
    jodiSum: 0,
    singleOpenSum: 0,
    singleCloseSum: 0,
    jodiCloseSum: 0,
    akOResult: 0,
    akCResult: 0,
    result: 0
  });

  const { singleSum, jodiSum, singleOpenSum, singleCloseSum, jodiCloseSum, akOResult, akCResult, result } = sums


  console.log('singleSum, jodiSum, singleOpenSum, singleCloseSum, jodiCloseSum', singleSum, jodiSum, singleOpenSum, singleCloseSum, jodiCloseSum, akOResult, akCResult, result);



  useEffect(() => {
    const calculateSums = () => {
      let singleSum = 0;
      let jodiSum = 0;
      let singleOpenSum = 0;
      let singleCloseSum = 0;
      let jodiCloseSum = 0;
      let akOResult = 0;
      let akCResult = 0;
      let result = 0;

      if (resultDataNum && resultDataNum.length > 0) {
        resultDataNum.forEach(element => {
          const markets = element?.markets || [];

          // Extracting common sums
          const getSum = (marketName) =>
            markets.filter(ele => ele?.localmarketname === marketName)
              .reduce((sum, record) => sum + (record.totalAmount || 0), 0);

          if (["akbar_open"].includes(element?.shortName)) {
            singleOpenSum = getSum('Single Open');
            jodiCloseSum = getSum('Jodi');
            akOResult = Number(element?.result) ?? 0
          }
          if (["akbar_close"].includes(element?.shortName)) {
            singleCloseSum = getSum('Single Close');
            akCResult = Number(element?.result) ?? 0
          }
          if (!["akbar_open", "akbar_close"].includes(element?.shortName)) {
            singleSum = getSum('Single Open') + getSum('Single Close');
            jodiSum = getSum('Jodi');
            result = Number(element?.result) ?? 0
          }
        });
      }

      // You can log the sums to check
      console.log("Single Open Sum: ", singleOpenSum, singleCloseSum, jodiCloseSum, singleSum, jodiSum, akOResult, akCResult, result);

      setSums({
        singleSum: singleSum,
        jodiSum: jodiSum,
        singleOpenSum: singleOpenSum,
        singleCloseSum: singleCloseSum,
        jodiCloseSum: jodiCloseSum,
        akOResult: akOResult,
        akCResult: akCResult,
        result: result
      })
    };

    calculateSums();
  }, [resultDataNum]);



  useEffect(() => {

    closePopup()

    createGameSubmit()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };



  }, []);


  useEffect(() => {
    if (isPopup) {
      openPopup()
    }
  }, [resultDataNum]);

  console.log('allMarket', allMarket);

  const createGameSubmit = async () => {

    let game = {
      date: fieldsGame?.date ?? today,
    };

    // Clear the previous interval, if any
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // Dispatch the action once immediately
    dispatch(reportActions.getNumberKarachiWiseMarketSum(game));

    // Start a new interval
    intervalRef.current = setInterval(async () => {
      const response = await dispatch(reportActions.getNumberKarachiWiseMarketSum(game));

      // Check condition to stop the interval
      if (response && response.payload && response.payload.stopCondition) {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // Reset the reference
      }
    }, 30000);
    // }
  };







  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));

    if (name === 'userId') {
      const game = gamesList?.find(ele => ele?._id === value)
      setGameName(game)
    }
  };



  let openSum = 0
  let closeSum = 0
  let centerSum = 0

  // const singleDigit = karachiResultDataNum?.find(ele => ele.marketName === "SINGLEDIGIT") ?? []
  const singleOpen = karachiResultDataNum && karachiResultDataNum?.openBets && karachiResultDataNum?.openBets?.all ? karachiResultDataNum?.openBets?.all : []
  const singleClose = karachiResultDataNum && karachiResultDataNum?.closedBets && karachiResultDataNum?.closedBets.all ? karachiResultDataNum?.closedBets.all : []

  const newOpenArr = [
    { betNumber: "0", totalAmount: 0 }, { betNumber: "1", totalAmount: 0 },
    { betNumber: "2", totalAmount: 0 }, { betNumber: "3", totalAmount: 0 },
    { betNumber: "4", totalAmount: 0 }, { betNumber: "5", totalAmount: 0 },
    { betNumber: "6", totalAmount: 0 }, { betNumber: "7", totalAmount: 0 },
    { betNumber: "8", totalAmount: 0 }, { betNumber: "9", totalAmount: 0 }
  ];

  // Loop through singleOpen array and update newOpenArr if there's a matching betNumber
  karachiResultDataNum?.openBets?.forEach(openBet => {
    const matchingItem = newOpenArr.find(item => item.betNumber === openBet.betNumber);
    if (matchingItem) {
      matchingItem.totalAmount = openBet.totalAmount;
      openSum += openBet.totalAmount
    }
  });
  const newCloseArr = [
    { betNumber: "0", totalAmount: 0 }, { betNumber: "1", totalAmount: 0 },
    { betNumber: "2", totalAmount: 0 }, { betNumber: "3", totalAmount: 0 },
    { betNumber: "4", totalAmount: 0 }, { betNumber: "5", totalAmount: 0 },
    { betNumber: "6", totalAmount: 0 }, { betNumber: "7", totalAmount: 0 },
    { betNumber: "8", totalAmount: 0 }, { betNumber: "9", totalAmount: 0 }
  ];

  // Loop through singleClose array and update newCloseArr if there's a matching betNumber
  karachiResultDataNum?.closeBets?.forEach(closeBet => {
    const matchingItem = newCloseArr.find(item => item.betNumber === closeBet.betNumber);
    if (matchingItem) {
      matchingItem.totalAmount = closeBet.totalAmount;
      closeSum += closeBet.totalAmount
    }
  });
  const newCenterArr = [
    { betNumber: "0", totalAmount: 0 }, { betNumber: "1", totalAmount: 0 },
    { betNumber: "2", totalAmount: 0 }, { betNumber: "3", totalAmount: 0 },
    { betNumber: "4", totalAmount: 0 }, { betNumber: "5", totalAmount: 0 },
    { betNumber: "6", totalAmount: 0 }, { betNumber: "7", totalAmount: 0 },
    { betNumber: "8", totalAmount: 0 }, { betNumber: "9", totalAmount: 0 }
  ];

  // Loop through singleClose array and update newCenterArr if there's a matching betNumber
  karachiResultDataNum?.centerBets?.forEach(centerBet => {
    const matchingItem = newCenterArr.find(item => item.betNumber === centerBet.betNumber);
    if (matchingItem) {
      matchingItem.totalAmount = centerBet.totalAmount;
      centerSum += centerBet.totalAmount
    }
  });

  const openPopup = () => {
    setOpen(true); // Set state to true to hide the popup
  };

  const closePopup = () => {
    setOpen(false); // Set state to false to hide the popup
  };

  const sortedJodiBets = karachiResultDataNum && karachiResultDataNum?.jodiBets ? karachiResultDataNum?.jodiBets : []
  console.log('sortedJodiBetssortedJodiBetssortedJodiBetssortedJodiBets', sortedJodiBets);
  const firstJodiBets = karachiResultDataNum && karachiResultDataNum?.fisrtJodiBets ? karachiResultDataNum?.fisrtJodiBets : []
  console.log('sortedJodiBetssortedJodiBetssortedJodiBetssortedJodiBets', firstJodiBets);
  const sortedRingBets = karachiResultDataNum && karachiResultDataNum?.finalResults ? karachiResultDataNum?.finalResults : []
  console.log('sortedJodiBetssortedJodiBetssortedJodiBetssortedJodiBets', sortedRingBets);

  const sumTotalAmount = (sortedRingBets || []).reduce((sum, record) => {
    const count = record.totalAmount - (fieldsGame?.cutting ?? 0); // Subtracting the cutting value from totalAmount
    if (count > 0) {
      return sum + count; // Adding the count to the sum if it's greater than 0
    }
    return sum; // If the count is 0 or less, return the current sum without adding anything
  }, 0);


  const remainingAmount = Math.ceil(sumTotalAmount) - (fieldsGame?.cutting ?? 0)
  const grossAmount = remainingAmount - (singleSum * 9 + jodiSum * 90)

  // Get the highest 15 bets by amount
  const sortedByAmountDesc = [...sortedRingBets].sort((a, b) => b.totalAmount - a.totalAmount);
  const jodiHighestBets = sortedByAmountDesc.filter(bet => bet.totalAmount > 0).slice(0, 15);

  // Get the lowest 15 bets by amount (ignoring zeroes)
  const sortedByAmountAsc = [...sortedRingBets].sort((a, b) => a.totalAmount - b.totalAmount);
  const jodiLowestBets = sortedByAmountAsc.filter(bet => bet.totalAmount > 0).slice(0, 15);

  const totalAmountResponse = gameName?.shortName === 'akbar_open' ? openSum : gameName?.shortName === 'akbar_close' ? closeSum : remainingAmount

  console.log({ jodiHighestBets, jodiLowestBets });


  // Function to toggle full screen
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen(); // Enter full-screen mode
      setIsFullScreen(true); // Update state to track full-screen mode
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen(); // Exit full-screen mode
        setIsFullScreen(false); // Update state to track the exit of full-screen
      }
    }
  };

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">

        <div className="relative z-20 flex min-h-screen">
          <div className="flex w-full overflow-hidden ">
            <div className="flex flex-col flex-1 overflow-hidden">
              <main className="relative flex-1">
                <div className="p-3 sm:p-5">
                  <div className="mx-auto max-w-screen-3xl">


                    <div className='bg-white p-2 my-3 rounded-md'>
                      <div className='grid lg:grid-cols-8 md:grid-cols-4 grid-cols-2 gap-2'>

                        <div className="flex flex-col space-y-2">
                          <div className='flex-1 pb-4 pr-4 bg-[#FF1900] border border-black'>
                            <table className="bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full">
                              <thead>
                                <tr className='text-[10px] font-normal'>
                                  <th className="border border-gray-300 font-normal">Num</th>
                                  <th className="border border-gray-300 font-normal">Highest_15</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(jodiHighestBets) && jodiHighestBets?.map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;
                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;


                                  return (
                                    <tr key={index} className='text-center text-[10px]'>
                                      <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className='flex-1 pb-4 pr-4 bg-[#00A300]  border border-black'>
                            <table className="bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full">
                              <thead>
                                <tr className='text-[10px] font-normal'>
                                  <th className="border border-gray-300 font-normal">Num</th>
                                  <th className="border border-gray-300 font-normal">Lowest_15</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(jodiLowestBets) && jodiLowestBets?.slice()?.map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;
                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-[10px]'>
                                      <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-center text-xs text-white'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">00 to 99</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(firstJodiBets) && firstJodiBets?.slice(0, 100).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-xs text-white font-normal'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">00 to 99</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedJodiBets) && sortedJodiBets?.slice(0, 100).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-center text-xs text-white'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">000 to 249</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedRingBets) && sortedRingBets?.slice(0, 250).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-xs text-white font-normal'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">250 to 499</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedRingBets) && sortedRingBets?.slice(250, 500).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-xs text-white font-normal'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">500 to 749</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedRingBets) && sortedRingBets?.slice(500, 750).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='h-[43rem] overflow-hidden overflow-y-auto'>
                          <div className='bg-[#FF8D7F] border border-black pr-4 pb-2'>
                            <table class="table-auto w-full border-collapse border border-gray-300 bg-[#006D7C]">
                              <thead>
                                <tr className='text-white text-xs font-normal'>
                                  <th class="border border-gray-300 font-normal">Num</th>
                                  <th class="border border-gray-300 font-normal">750 to 999</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedRingBets) && sortedRingBets?.slice(750, 1000).map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;

                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-base font-semibold text-white'>
                                      {ele?.isBetPrime ?
                                        <td className="border border-gray-300 bg-red-600">{ele?.betNumber ?? null}</td>
                                        :
                                        <td className="border border-gray-300">{ele?.betNumber ?? null}</td>
                                      }
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className='flex flex-col space-y-2 h-[43rem] overflow-hidden overflow-y-auto'>



                          <div className='bg-[#500F83] flex-1 pb-4 pr-4 border border-black'>
                            <table className="bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full">
                              <thead>
                                <tr className='text-xs font-normal'>
                                  <th className="border border-gray-300 font-normal">Num</th>
                                  <th className="border border-gray-300"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(newCloseArr) && newCloseArr?.map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;
                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-xs'>
                                      <td className="border border-gray-300">X{ele?.betNumber ?? null}</td>
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className='flex border border-yellow-800 bg-yellow-300 text-black px-2 justify-between items-center'>
                            <p>Close Amount:</p>
                            <p>{closeSum ?? 0}</p>
                          </div>


                          <div className='bg-[#500F83] flex-1 pb-4 pr-4 border border-black'>
                            <table className="bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full">
                              <thead>
                                <tr className='text-xs font-normal'>
                                  <th className="border border-gray-300 font-normal">Num</th>
                                  <th className="border border-gray-300"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(newCloseArr) && newCloseArr?.map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;
                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-xs'>
                                      <td className="border border-gray-300">X{ele?.betNumber ?? null}</td>
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className='flex border border-yellow-800 bg-yellow-300 text-black px-2 justify-between items-center'>
                            <p>Close Amount:</p>
                            <p>{closeSum ?? 0}</p>
                          </div>


                          <div className='bg-[#500F83] flex-1 pb-4 pr-4 border border-black'>
                            <table className="bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full">
                              <thead>
                                <tr className='text-xs font-normal'>
                                  <th className="border border-gray-300 font-normal">Num</th>
                                  <th className="border border-gray-300 font-normal"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(newCenterArr) && newCenterArr?.map((ele, index) => {
                                  const count = ele?.totalAmount ? ele?.totalAmount - (fieldsGame?.cutting ?? 0) : 0;
                                  const formattedCount = count > 0
                                    ? count % 1 === 0
                                      ? count
                                      : count.toFixed(2)
                                    : 0;

                                  return (
                                    <tr key={index} className='text-center text-xs'>
                                      <td className="border border-gray-300">{ele?.betNumber ?? null}X</td>
                                      <td className="border border-gray-300">{formattedCount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className='flex border border-yellow-800 bg-yellow-300 text-black px-2 justify-between items-center'>
                            <p>Center Amount:</p>
                            <p>{centerSum ?? 0}</p>
                          </div>

                        </div>

                      </div>
                    </div>


                    <div className='grid lg:grid-cols-7 md:grid-cols-2 grid-cols-1 gap-2'>


                      <div className="w-full mt-4">
                        <label class="block text-gray-700 text-sm mb-1 font-medium" for="gamename">  Date</label>
                        <input className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`} id="date" name="date" placeholder="Enter Date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} max={today} type="date" onChange={inputChangeCreate} />
                        {errorsGame && errorsGame["date"] ? (
                          <div className="text-red-600 text-sm invalid-feedback">
                            {errorsGame["date"]}
                          </div>
                        ) : null}
                      </div>

                      <div className="w-full mt-10">
                        <button className="flex justify-center w-full px-4 py-1 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={() => createGameSubmit()}>Submit</button>
                      </div>

                      <div className="w-full mt-4">
                        <label className="block text-gray-700 text-sm mb-1 font-medium">Cutting</label>
                        <input className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`} id="cutting" name="cutting" placeholder="Cutting number." value={fieldsGame && fieldsGame["cutting"] ? fieldsGame["cutting"] : ""} type="number" onChange={inputChangeCreate} />
                        {errorsGame && errorsGame["cutting"] ? (
                          <div className="text-red-600 text-sm invalid-feedback">
                            {errorsGame["cutting"]}
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full mt-4">
                        <label className="block text-gray-700 text-sm mb-1 font-medium">Remaining Amount</label>
                        <input className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`} id="result" name="result" value={remainingAmount && remainingAmount < 0 ? 0 : remainingAmount} type="number" />
                      </div>
                      <div className="w-full mt-4 flex items-center space-x-2">
                        <div className="mt-6 cursor-pointer" onClick={toggleFullScreen}>
                          {isFullScreen ? (
                            <CgMaximize className="w-7 h-7" />
                          ) : (
                            <FiMinimize className="w-7 h-7" />
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </main>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default Karachi;
