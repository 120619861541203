import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./WalletManagement.json";
import { gamesActions } from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import Select from "react-select";




const AddFundReport = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [isAkbar, setIsAkbar] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  console.log('numberListnumberListnumberListnumberListnumberListnumberList', numberList);

  const today = new Date().toISOString().split("T")[0];

  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { allMarket } = useSelector((state) => state.markets);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);


  const numberListJodi = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
    "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
    "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
    "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
    "50", "51", "52", "53", "54", "55", "56", "57", "58", "59",
    "60", "61", "62", "63", "64", "65", "66", "67", "68", "69",
    "70", "71", "72", "73", "74", "75", "76", "77", "78", "79",
    "80", "81", "82", "83", "84", "85", "86", "87", "88", "89",
    "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"
  ]
  const numberListSingle = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
  ]

  // const numberList = isAkbar ? numberListSingle : numberListJodi


  useEffect(() => {
    if (fieldsGame?.gameId) {
      const resData = isAkbar ? numberListSingle : numberListJodi;
      if (Array.isArray(resData)) {
        setNumberList(resData);
      }
    }
  }, [fieldsGame]);

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: false,
    };

    dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getResultListAdmin(listReq));
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(gamesActions.marketNumberThreeDigit());
  }, [pageNo]);

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));

    console.log(' name, value name, value name, value name, value', name, value);

    const filterDataRes = gamesList?.find((ele) => ele?._id == value);

    console.log('2222222222222222222222222222', filterDataRes);
    if (filterDataRes?.shortName == 'akbar_open' || filterDataRes?.shortName == 'akbar_close') {
      setIsAkbar(true)
    } else {
      setIsAkbar(false)
    }
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      console.log("sdhvsadjsabdsabdksab");
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: keyWord,
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
        brandGame: false,
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        // isOpen: fieldsGame.isOpen == "true" ? true : false,
        date: fieldsGame?.date,
        // brandGame: false,
      };

      dispatch(gamesActions.resultDeclare(obj, gamePageRefresh));
      // setFieldsGame({})
    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === "") {
      formIsValid = false;
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    if (!fieldsUpdate.backgroundColor || fieldsUpdate.backgroundColor === "") {
      formIsValid = false;
      errors.backgroundColor = DashboardJSON.ErrorMsg.backgroundColor;
    }
    console.log("errorsUpdate????", errorsUpdate);
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    if (!fieldsGame.num || fieldsGame.num === "") {
      formIsValid = false;
      errors.num = "Game is Required";
    }

    // if (!fieldsGame.isOpen || fieldsGame.isOpen === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Select Sessions";
    // }

    // if (!fieldsGame.num || fieldsGame.num === "") {
    //   formIsValid = false;
    //   errors.num = "Empty fields";
    // }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      brandGame: false,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };

  const ShowWinnerList = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num?.toString() == "000" ? "0" : fieldsGame?.num,
      isOpen: fieldsGame.isOpen == "true" ? true : false,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.getWinnerListByBetNumber(obj));
      setViewModal(true);
    }
  };
  const ShowWinnerList2 = () => {
    let obj = {
      isJackPot: false,
      isStarLine: false,
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: fieldsGame.isOpen == "true" ? true : false,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
      // setViewModal(true)
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };

  const rollback = (e) => {
    let obj = {
      gameId: e?.gameId?._id,
      closeNumber: e?.closeNumber == 0 ? "000" : e?.closeNumber?.toString(),
      jodiNumber: e?.jodiNumber,
      openNumber: e?.openNumber == 0 ? "000" : e?.openNumber?.toString(),
      date: e && e.rDate && new Date(e.rDate).toISOString().split("T")[0],
    };

    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: false,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Rollback",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.rollbackResultDeclare(obj, listReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "gray-900",
      "&:hover": { borderColor: "gray-900" },
      boxShadow: "none",
    }),
    option: (base) => ({
      ...base,
      color: "#495057",
    }),
  };

  let { games } = selector ? selector : {};
  let {
    loading,
    gameResultList,
    gameResultTotal,
    winnerList,
    resultList,
    numberArray,
    // numberList,
  } = games ? games : {};

  let optionsData =
    gamesList && gamesList.length > 0 && gamesList.filter((e) => !e?.brandGame);



  return (
    <>
      <Loader loading={loading} />
      <Loader loading={selector?.games?.loading} />

      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white space-y-2 rounded-lg">
                        <div className="px-6 py-2 flex items-center justify-between border-b">
                          <span className="text-lg font-bold">
                            Save Result
                          </span>
                        </div>
                        <div className="px-6 py-4 w-full">
                          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            <div className="w-ful">
                              <label
                                class="block text-gray-700 text-xs font-semibold mb-1"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              <input
                                className={`w-full px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-ful">
                              <label
                                class="block text-gray-700 text-xs font-semibold mb-1"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {optionsData && optionsData.length > 0
                                  ? optionsData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ""
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>


                            <div className="w-ful">
                              <label className="block text-gray-700 text-xs font-semibold mb-1">
                                Number
                              </label>
                              <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                      value: fieldsGame["num"],
                                      label: fieldsGame["num"],
                                    }
                                    : ""
                                }
                                options={
                                  Array.isArray(numberList)
                                    ? numberList.map((option) => ({
                                      value: option,
                                      label: option,
                                    }))
                                    : []
                                }

                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className=" w-full"
                              />
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full flex items-center space-x-4">
                              <div className="w-full xl:pt-4 lg:pt-0 md:pt-4 pt-0">
                                <button className="flex-1 justify-center w-full px-4 py-1 text-sm text-white transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor" type="button" onClick={(e) => createGameSubmit(e)}>Save</button>
                              </div>

                              <div className="w-full xl:pt-4 lg:pt-0 md:pt-4 pt-0">
                                <button className="flex-1 justify-center w-full px-2 whitespace-nowrap py-1 text-sm text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600" type="button" onClick={(e) => ShowWinnerList(e)}>Show Winner List</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              <th className="px-3 py-3 text-left">
                                #
                              </th>
                              <th className="px-3 py-3 text-left">
                                Result Date
                              </th>
                              <th className="px-3 py-3 text-left">
                                Game Name
                              </th>
                              <th className="px-3 py-3 text-left">
                                Jodi Number
                              </th>
                              {/* <th scope="col" className="px-2 lg:px-4 py-3 text-left text-[10px] lg:text-[15px]">
                                Rollback
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0
                              ? gameResultList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white border-b  "
                                        : "bg-gray-100  "
                                    }
                                  >
                                    <td
                                      scope="row"
                                      className="px-2 lg:px-4 py-2 text-sm text-left"
                                    >
                                      {offset + index + 1}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-2 lg:px-4 py-2 text-sm text-left"
                                    >
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-2 lg:px-4 py-2 text-sm text-left capitalize"
                                    >
                                      {element && element.gameId
                                        ? element.gameId?.name
                                        : "-"}
                                    </td>

                                    <td
                                      scope="row"
                                      className="px-2 lg:px-4 py-2 text-sm text-left"
                                    >
                                      {element &&
                                        element.jodiNumber?.toString()
                                        ? element.jodiNumber?.toString()
                                        : "-"}
                                    </td>

                                    {/* <td
                                      scope="row"
                                      className="px-2 lg:px-4 py-2 text-sm text-left"
                                    >
                                      {element.jodiNumber &&
                                        element.jodiNumber &&
                                        element.jodiNumber.length == 2 ? (
                                        <span
                                          className=" text-red-500 cursor-pointer"
                                          onClick={() => rollback(element)}
                                        >
                                          <span className="block lg:hidden"><AiOutlineRollback className="text-lg" />
                                          </span>  <span className="hidden lg:block">RoleBack</span>
                                        </span>
                                      ) : (
                                        "-"
                                      )}

                                    </td> */}
                                  </tr>
                                </React.Fragment>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {gameResultList && gameResultTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(gameResultTotal / size)}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={10}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default AddFundReport;
