import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./AppSettingManagement.json";
import { appSettingActions } from "../../_actions";
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import { FaRegSave } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import Table from '../../components/Atoms/Table/Table';

const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(1000);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const { appSettingList, total, loading } = useSelector(
    (state) => state.appSettings
  );

  const [initialState, setInitialState] = useState({
    _id: "",
    name: "",
    key: "",
    value: "",
  });


  useEffect(() => {
    let appsettingReq = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(appSettingActions.getAppSettingList(appsettingReq));
  }, []);


  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  };

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    // console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(
        appSettingActions.updateAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setUpdateModal,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(
        appSettingActions.createAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setCreateModal,
          setFieldsAppSetting,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsAppSetting(errors);
    return formIsValid;
  };

  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      id: data._id,
    };
    let appsettingPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: "Confirm to disable AppSetting?",
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              appSettingActions.updateAppSettingStatus(
                appsettingIdReq,
                appsettingPageRefresh
              )
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(
        appSettingActions.uploadImage(
          event.target.files[event.target.files.length - 1]
        )
      );
      // this.setState({ selectedFile: null });
      setSelectedFile(null);
    } else {
      console.log("No File To Upload!");
    }
  };

  const [state1, setstate1] = useState("");
  const [state2, setstate2] = useState("");
  const [state3, setstate3] = useState("");
  const [state4, setstate4] = useState("");
  const [state5, setstate5] = useState("");
  const [state6, setstate6] = useState("");
  const [state7, setstate7] = useState("");
  const [state8, setstate8] = useState("");
  const [state9, setstate9] = useState("");
  const [state10, setstate10] = useState("");
  const [state11, setstate11] = useState("");
  const [state12, setstate12] = useState("");
  const [state13, setstate13] = useState("");


  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {

        console.log('element.key', element.key);

        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(element.value);
        }
        if (element.key === "Deposit_Min") {
          setstate6(element.value);
        }
        if (element.key === "Deposit_Max") {
          setstate7(element.value);
        }
        if (element.key === "Withdraw_Min") {
          setstate8(element.value);
        }
        if (element.key === "Withdraw_Max") {
          setstate9(element.value);
        }
        if (element.key === "Withdrawal_Time_Open") {
          setstate10(element.value);
        }
        if (element.key === "Withdrawal_Time_Close") {
          setstate11(element.value);
        }
        if (element.key === "Deposit_Time_Open") {
          setstate12(element.value);
        }
        if (element.key === "Deposit_Time_Close") {
          setstate13(element.value);
        }
        if (element.key === "MobileNumber") {
          setstate2(element.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
        if (element.key === "NOTICE") {
          setValue2(element.value);
        }
        if (element.key === "Deposit_Notice") {
          setValue3(element.value);
        }
        if (element.key === "FAQ") {
          setValue4(element.value);
        }
        if (element.key === "TESTIMONIAL") {
          setValue5(element.value);
        }
        if (element.key === "NOTICE_RESULT") {
          setValue6(element.value);
        }
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    console.log(e);
    let dataofvalue;
    if (e.key === "NOTICE") {
      dataofvalue = value2;
    }
    if (e.key === "Deposit_Notice") {
      dataofvalue = value3;
    }
    if (e.key === "FAQ") {
      dataofvalue = value4;
    }
    if (e.key === "TESTIMONIAL") {
      dataofvalue = value5;
    }
    if (e.key === "NOTICE_RESULT") {
      dataofvalue = value6;
    }
    if (e.key === "TelegramLink") {
      dataofvalue = state5;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }
    if (e.key === "WhatsApp") {
      dataofvalue = state3;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = state2;
    }
    if (e.key === "Deposit_Min") {
      dataofvalue = state6;
    }
    if (e.key === "Deposit_Max") {
      dataofvalue = state7;
    }
    if (e.key === "Withdraw_Min") {
      dataofvalue = state8;
    }
    if (e.key === "Withdraw_Max") {
      dataofvalue = state9;
    }
    if (e.key === "Withdrawal_Time_Open") {
      dataofvalue = state10;
    }
    if (e.key === "Withdrawal_Time_Close") {
      dataofvalue = state11;
    }
    if (e.key === "Deposit_Time_Open") {
      dataofvalue = state12;
    }
    if (e.key === "Deposit_Time_Close") {
      dataofvalue = state13;
    }
    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    let appsettingPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id,
    };
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh));
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <div>
                        <p className="text-xl font-bold pb-2">App Setting Management</p>
                        <div className="relative flex flex-col gap-5 rounded-md">
                          <div className="flex md:flex-row flex-col justify-center p-3 bg-white border rounded-md items-center gap-5  ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "TelegramLink") {
                                  return (
                                    <form class="flex  w-full justify-start items-center  ">
                                      <div className=" relative w-full">
                                        <label for="text" class="block mb-1 text-sm font-medium text-gray-900">  {element && element.name}</label>
                                        <input value={state5} onChange={(e) => setstate5(e.target.value)} type="text" id="text" class="bg-gray-50  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        <FaRegSave title="Save" onClick={(e) => saveInfo(element, e)} className="text-[#992941] absolute top-1/2 right-2 text-xl cursor-pointer" />
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "MobileNumber") {
                                  return (
                                    <form class="  w-full   p-2  mt-2 ">
                                      <div className=" w-full relative">
                                        <label for="text" class="block mb-1 text-sm font-medium text-gray-900"> User Pannel Marquee</label>
                                        <input value={state2} onChange={(e) => setstate2(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                      </div>
                                      <div className="flex justify-end mt-1">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }
                                if (element.key === "WhatsApp") {
                                  return (
                                    <form class=" w-full  mt-2 ">
                                      <label for="text" class="block mb-1 text-gray-900">  Admin Pannel Marquee</label>
                                      <input value={state3} onChange={(e) => setstate3(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className=" text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "Email") {
                                  return (
                                    <form class="flex w-full  justify-start items-center mt-2 ">
                                      <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name}</label>
                                      <input value={state4} onChange={(e) => setstate4(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                      <FaRegSave title="Save" onClick={(e) => saveInfo(element, e)} className="text-[#992941] absolute top-1/2 right-2 text-xl cursor-pointer" />
                                    </form>
                                  );
                                }
                                if (element.key === "YoutubeLink") {
                                  return (
                                    <form class="flex  justify-start w-full items-center mt-2 ">
                                      <div className="w-full relative">
                                        <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name}</label>
                                        <input value={state1} onChange={(e) => setstate1(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        <FaRegSave title="Save" onClick={(e) => saveInfo(element, e)} className="text-[#992941] absolute top-1/2 right-2 text-xl cursor-pointer" />
                                      </div>
                                    </form>
                                  );
                                }
                              })
                              : null}
                          </div>
                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 bg-white rounded-md p-4 gap-5">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "Deposit_Min") {
                                  return (
                                    <form class="w-full mt-2 ">
                                      <label for="text" class="block mb-1 text-gray-900 text-xs font-medium">Deposit Min</label>
                                      <input value={state6} onChange={(e) => setstate6(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "Deposit_Max") {
                                  return (
                                    <form class=" w-full  mt-2 ">
                                      <label for="text" class="block mb-1 text-gray-900 text-xs font-medium">Deposit Max</label>
                                      <input value={state7} onChange={(e) => setstate7(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required
                                      />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">Save</button>
                                      </div>
                                    </form>
                                  );
                                }
                              })
                              : null}

                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "Deposit_Time_Open") {
                                  return (
                                    <form className="w-full mt-2">
                                      <label htmlFor="timeOpen" className="block mb-1 text-gray-900 text-xs font-medium">
                                        Deposit Time Open
                                      </label>
                                      <input
                                        type="time"
                                        id="timeOpen"
                                        value={state12}
                                        onChange={(e) => setstate12(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "Deposit_Time_Close") {
                                  return (
                                    <form className="w-full mt-2">
                                      <label htmlFor="timeClose" className="block mb-1 text-gray-900 text-xs font-medium">
                                        Deposit Time Close
                                      </label>
                                      <input
                                        type="time"
                                        id="timeClose"
                                        value={state13}
                                        onChange={(e) => setstate13(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">
                                        <button
                                          title="Save"
                                          onClick={(e) => saveInfo(element, e)}
                                          className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </form>
                                  );
                                }

                              })
                              : null}
                          </div>


                          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 bg-white rounded-md p-4 gap-5">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "Withdraw_Min") {
                                  return (
                                    <form class=" w-full  mt-2 ">
                                      <label for="text" class="block mb-1 text-gray-900 text-xs font-medium">Withdraw Min</label>
                                      <input
                                        value={state8}
                                        onChange={(e) =>
                                          setstate8(e.target.value)
                                        }
                                        type="text"
                                        id="text"
                                        class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">

                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "Withdraw_Max") {
                                  return (
                                    <form class=" w-full  mt-2 ">
                                      <label for="text" class="block mb-1 text-gray-900 text-xs font-medium">Withdraw Max</label>
                                      <input
                                        value={state9}
                                        onChange={(e) =>
                                          setstate9(e.target.value)
                                        }
                                        type="text"
                                        id="text"
                                        class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">Save</button>
                                      </div>
                                    </form>
                                  );
                                }
                              })
                              : null}

                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "Withdrawal_Time_Open") {
                                  return (
                                    <form className="w-full mt-2">
                                      <label htmlFor="timeOpen" className="block mb-1 text-gray-900 text-xs font-medium">
                                        Withdrawal Time Open
                                      </label>
                                      <input
                                        type="time"
                                        id="timeOpen"
                                        value={state10}
                                        onChange={(e) => setstate10(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">
                                        <button title="Save" onClick={(e) => saveInfo(element, e)} className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm">  Save</button>
                                      </div>
                                    </form>
                                  );
                                }

                                if (element.key === "Withdrawal_Time_Close") {
                                  return (
                                    <form className="w-full mt-2">
                                      <label htmlFor="timeClose" className="block mb-1 text-gray-900 text-xs font-medium">
                                        Withdrawal Time Close
                                      </label>
                                      <input
                                        type="time"
                                        id="timeClose"
                                        value={state11}
                                        onChange={(e) => setstate11(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">
                                        <button
                                          title="Save"
                                          onClick={(e) => saveInfo(element, e)}
                                          className="text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </form>
                                  );
                                }

                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "HowToPlay"
                                ) {
                                  return (
                                    <div className="">
                                      <div className="mb-1">How To Play</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value} onChange={setValue} />
                                      <div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "NOTICE"
                                ) {
                                  return (
                                    <div className="">
                                      <div className=" mb-1">Withdraw Notice</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value2} onChange={setValue2} /><div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button></div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "Deposit_Notice"
                                ) {
                                  return (
                                    <div className="">
                                      <div className="mb-1"> Deposit Notice</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value3} onChange={setValue3} />
                                      <div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "FAQ"
                                ) {
                                  return (
                                    <div className="">
                                      <div className="mb-1">FAQ</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value4} onChange={setValue4} />
                                      <div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "TESTIMONIAL"
                                ) {
                                  return (
                                    <div className="">
                                      <div className="mb-1">Testimonial</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value5} onChange={setValue5} />
                                      <div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                          <div className="flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (
                                  element &&
                                  element.key &&
                                  element.key === "NOTICE_RESULT"
                                ) {
                                  return (
                                    <div className="">
                                      <div className="mb-1">Notice Result</div>
                                      <ReactQuill className="h-56 w-full pb-14" theme="snow" value={value6} onChange={setValue6} />
                                      <div className="flex justify-end">
                                        <button className="bgcolor text-white mt-2 rounded px-3 py-0.5 text-sm" onClick={(e) => saveInfo(element, e)}>Save</button>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                              : null}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  );
};

export default AppSettingManagement;
