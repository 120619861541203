import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./WalletManagement.json";
import { gamesActions, reportActions } from "../../_actions";
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

// import Table from '../../components/Atoms/Table/Table';

const Mybets = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();

  let { games, reports } = selector ? selector : {};
  let { getBetWLists, loading } = reports ? reports : {};
  let { list, total } = getBetWLists ? getBetWLists : {}

  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});

  // console.log('options.....', options);
  // const { reportsList, reportsTotal,loading } = useSelector(state => state.reports);
  const { userList, userTotal } = useSelector((state) => state.reports);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [Options, setOptions] = useState([]);
  const [toDate, settoDate] = useState("");
  const [selecteddata, setselecteddata] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    // dispatch(gamesActions.getAllGames(gameReq2))
    // dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(reportActions.getBetsList(gameReq));
    // dispatch(reportActions.getAllEntries(gameReq));
    // dispatch(gamesActions.getAllMarket())
    // dispatch(gamesActions.getAllUser())
  }, []);

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id,
      }));
      console.log(options);
      setOptions(options);
    }
  }, [allUserList]);

  console.log(Options);
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate2 = (e) => {
    console.log(e);
    setselecteddata(e);
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    let gameReq2 = {
      fromDate: fieldsGame?.date || "",
      toDate: fieldsGame?.todate || "",
      keyWord: fieldsGame?.keyWord || "",
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
    };
    dispatch(reportActions.getBetsList(gameReq2));
    // }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select Game Name";
    }

    if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
      formIsValid = false;
      errors.userId2 = "Select Game Type";
    }

    if (!fieldsGame.todate || fieldsGame.todate === "") {
      formIsValid = false;
      errors.todate = "Select Date";
    }

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Select Date";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage(data.selected);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,

    };
    dispatch(reportActions.getBetsList(pageReq));
  };

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    let pageReq = {
      type: "WITHDRAW",
      keyWord: value,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: 100,
    };
    // dispatch(walletActions.getPaymentListForAdmin(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    let pageReq = {
      // "type": "WITHDRAW",
      keyWord: keyWord,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: value,
      userId: selecteddata,
    };
    dispatch(reportActions.getAllEntries(pageReq));
  };
  const [selectedValues, setSelectedValues] = useState([]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };
  let sessionData = JSON.parse(window.sessionStorage.getItem("adminuser"));

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-2">
                      {/* <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl '>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Bid History (Main Market)</span></div>
                      </sectiion> */}

                      <div className="flex justify-between sm:flex-nowrap gap-3 flex-wrap items-end w-full">
                        <div className="mt-1 shadow-sm w-full">
                          <label class=" mb-1 block  text-sm font-medium text-gray-900">From Date :</label>
                          <input className="w-full px-3 py-1 text-sm bg-white border border-gray-400 rounded focus:outline-none" id="date" name="date" placeholder="Enter date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} max={today} type="date" onChange={inputChangeCreate} />
                          {errorsGame && errorsGame["date"] ? (
                            <div className="text-red-600  invalid-feedback">{errorsGame["date"]}</div>
                          ) : null}
                        </div>
                        <div className="mt-1 shadow-sm w-full">
                          <label class=" mb-1 block text-sm font-medium text-gray-900">To Date :</label>
                          <input className="w-full px-3 py-1 text-sm bg-white border border-gray-400 rounded focus:outline-none" id="date" name="todate" placeholder="Enter date" value={fieldsGame && fieldsGame["todate"] ? fieldsGame["todate"] : ""} max={today} type="date" onChange={inputChangeCreate} />
                          {errorsGame && errorsGame["todate"] ? (
                            <div className="text-red-600 invalid-feedback">{errorsGame["todate"]} </div>
                          ) : null}
                        </div>
                        <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                          <button className="flex w-full lg:w-auto justify-center px-4 py-1 text-base text-white capitalize transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                        </div>
                      </div>

                      <div className="text-left bg-white rounded-lg pb-4">
                        <div className="flex justify-between items-center px-3 py-2 border-b">
                          <span className="text-left rtl:text-right text-gray-900 bg-white">My bet statements</span>
                        </div>

                        <div className="relative overflow-x-auto">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Game</th>
                                <th className="px-6 py-3">Market</th>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Bet Number</th>
                                <th className="px-6 py-3">Date</th>
                                <th className="px-6 py-3">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list && list.length > 0 ? (
                                list.map((element, index) => {
                                  console.log("elementelement", element?.amount)
                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className="bg-white border-b border-gray-300"
                                      >
                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {offset + index + 1}
                                        </th>

                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {element &&
                                            element?.gameId &&
                                            element?.gameId?.name}
                                        </th>

                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {['Double Kenchi', 'Single Kenchi'].includes(element?.localmarketname) ? 'Jodi' : element && element?.localmarketname}
                                        </th>



                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {element && element?.amount
                                          }
                                        </th>


                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {element && element?.betNumber
                                          }
                                        </th>


                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-gray-600 whitespace-nowrap "
                                        >
                                          {element && element.createdAt
                                            ? moment(
                                              new Date(
                                                parseInt(element.createdAt)
                                              )
                                            )
                                              .utcOffset("+05:30")
                                              .format("YYYY-MM-DD hh:mm:ss a")
                                            : "-"}
                                        </th>


                                        <td className="px-6 py-2 text-start">
                                          {element?.betStatus == 1 && <div className=' text-green-600'>{element?.winAmount || "--"}Win</div>}
                                          {element?.betStatus == 2 && <div className=' text-red-600'>{element?.lossAmount || "--"}Loss</div>}
                                          {element?.betStatus == 0 && <div className=' text-yellow-700'>Pending</div>}

                                        </td>


                                      </tr>
                                    </React.Fragment>
                                  )
                                })
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {total && total > size ? (
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={Math.ceil(total / size)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mybets;
