export const karachiGamesConstants = {

    CREATE_KARACHI_GAMES_REQUEST: "CREATE_KARACHI_GAMES_REQUEST",
    CREATE_KARACHI_GAMES_SUCCESS: "CREATE_KARACHI_GAMES_SUCCESS",
    CREATE_KARACHI_GAMES_FAILURE: "CREATE_KARACHI_GAMES_FAILURE",

    GET_KARACHI_GAMES_LIST_REQUEST: "GET_KARACHI_GAMES_LIST_REQUEST",
    GET_KARACHI_GAMES_LIST_SUCCESS: "GET_KARACHI_GAMES_LIST_SUCCESS",
    GET_KARACHI_GAMES_LIST_FAILURE: "GET_KARACHI_GAMES_LIST_FAILURE",

    GET_ALL_KARACHI_GAMES_REQUEST: "GET_ALL_KARACHI_GAMES_REQUEST",
    GET_ALL_KARACHI_GAMES_SUCCESS: "GET_ALL_KARACHI_GAMES_SUCCESS",
    GET_ALL_KARACHI_GAMES_FAILURE: "GET_ALL_KARACHI_GAMES_FAILURE",

    UPDATE_KARACHI_GAMES_REQUEST: "UPDATE_KARACHI_GAMES_REQUEST",
    UPDATE_KARACHI_GAMES_SUCCESS: "UPDATE_KARACHI_GAMES_SUCCESS",
    UPDATE_KARACHI_GAMES_FAILURE: "UPDATE_KARACHI_GAMES_FAILURE",

    PLACEBET_BY_ADMIN_KARACHI_FREEZE_REQUEST: "PLACEBET_BY_ADMIN_KARACHI_FREEZE_REQUEST",
    PLACEBET_BY_ADMIN_KARACHI_FREEZE_SUCCESS: "PLACEBET_BY_ADMIN_KARACHI_FREEZE_SUCCESS",
    PLACEBET_BY_ADMIN_KARACHI_FREEZE_FAILURE: "PLACEBET_BY_ADMIN_KARACHI_FREEZE_FAILURE",

    KARACHI_PLACEBET_BY_ADMIN_BRAND_REQUEST: "KARACHI_PLACEBET_BY_ADMIN_BRAND_REQUEST",
    KARACHI_PLACEBET_BY_ADMIN_BRAND_SUCCESS: "KARACHI_PLACEBET_BY_ADMIN_BRAND_SUCCESS",
    KARACHI_PLACEBET_BY_ADMIN_BRAND_FAILURE: "KARACHI_PLACEBET_BY_ADMIN_BRAND_FAILURE",

    KARACHI_PLACEBET_BY_ADMIN_FREEZE_REQUEST: "KARACHI_PLACEBET_BY_ADMIN_FREEZE_REQUEST",
    KARACHI_PLACEBET_BY_ADMIN_FREEZE_SUCCESS: "KARACHI_PLACEBET_BY_ADMIN_FREEZE_SUCCESS",
    KARACHI_PLACEBET_BY_ADMIN_FREEZE_FAILURE: "KARACHI_PLACEBET_BY_ADMIN_FREEZE_FAILURE",
};
