import {
  karachiMarketConstants
} from '../_constants';

export default function karachiMarket(state = {}, action) {
  switch (action.type) {

    case karachiMarketConstants.CREATE_KARACHI_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.CREATE_KARACHI_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiMarketConstants.CREATE_KARACHI_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.GET_ALL_KARACHI_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.GET_ALL_KARACHI_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allKarachiMarket: action.market.data,
        // marketTotal: action.market.data.total,
        loading: false,
      };
    case karachiMarketConstants.GET_ALL_KARACHI_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.GET_KARACHI_MARKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.GET_KARACHI_MARKET_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiMarketList: action.market.data.list,
        karachiMarketTotal: action.market.data.total,
        loading: false,
      };
    case karachiMarketConstants.GET_KARACHI_MARKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiMarketDataIdWise: action.market.data,
        loading: false,
      };
    case karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.UPDATE_KARACHI_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.UPDATE_KARACHI_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiMarketConstants.UPDATE_KARACHI_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.DELETE_KARACHI_MARKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.DELETE_KARACHI_MARKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiMarketConstants.DELETE_KARACHI_MARKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case karachiMarketConstants.FILE_UPLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiMarketConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: true,
        // filesDetails: action.uploadImage.filesDetails
      };
    case karachiMarketConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case karachiMarketConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };


    default:
      return state
  }
}