import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DashboardJSON from "./UserManagement.json";
import { reportActions, userActions, walletActions } from "../../_actions";
import CreateUserModal from "./components/CreateUserModal/CreateUserModal";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { confirmAlert } from "react-confirm-alert";
import { RiFileList3Fill } from "react-icons/ri";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BiEdit } from "react-icons/bi";
import { RiFileList2Line } from "react-icons/ri";
import Loader from "../../components/Loader/Loader";
import { FiEdit } from "react-icons/fi";
import { FaStreetView } from "react-icons/fa6";
import ModelTest from "./ModelTest";
import { HiClipboardList } from "react-icons/hi";

const UserSuperadmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let [rowData, setRowData] = useState(null);
  let [userOpenModal, setCreateModal] = useState(false);
  // let [viewModal, setViewModal] = useState(false);
  const [fieldsUser, setfieldsUser] = useState({});
  const [FieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUser, setErrorsUpdate] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [isOpen3, setisOpen3] = useState(false);
  const onClose3 = () => {
    setisOpen3(false);
  };
  let selector = useSelector((state) => state);
  let { games, reports } = selector ? selector : {};
  const { userList, userTotal } = useSelector((state) => state.users);
  let addRef = useRef(null);

  useEffect(() => {
    let myBetReq = {
      keyWord: "",
      pageNo: pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };
    dispatch(userActions.getUserList(myBetReq));
    dispatch(reportActions.getLedgerforUser());
    dispatch(userActions.getAdminProfile());
  }, []);

  const handleOpenModal = () => {
    setCreateModal(true);
  };

  const handleHideModal = (data) => {
    dispatch(userActions.clearImage());
    if (addRef.current) {
      addRef.current.value = "";
    }
    setCreateModal(false);
    setErrorsUpdate({});
    setfieldsUser({});
  };
  const [rowData, setrowData] = useState({});
  const [viewModal, setviewModal] = useState(false);
  // const handleViewOpenModal = (data) => {
  //   setRowData(data);
  //   setViewModal(true);
  // };

  const handleViewHideModal = (data) => {
    setviewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setfieldsUser((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createUserSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    e.preventDefault();
    if (handleValidationUser()) {
      let userPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        sortBy: "slug",
        sortOrder: "asc",
        fromDate: "",
        toDate: "",
        size: size,
        // roleId:1,
      };

      const { userName, mobNo, email, comm, share, password, confirmPassword } =
        fieldsUser;

      let obj = {
        userName: userName && userName.trim(),
        mobNo: mobNo && mobNo.trim(),
        comm: comm || "0",
        share: share || "0",
        password: password.trim(),
        confirmPassword: confirmPassword && confirmPassword.trim(),
        image: fileData ? fileData : " ",
        roleId: 1,
      };

      dispatch(userActions.registerUserByAdmin(obj, userPageRefresh));
      handleHideModal();
    }
  };

  const handleValidationUser = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = DashboardJSON.ErrorMsg.userName;
    }

    if (!fieldsUser.userName || fieldsUser.userName.length < 5) {
      formIsValid = false;
      errors.userName = "User name must be at least 5 characters";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = DashboardJSON.ErrorMsg.mobNo;
    }

    if (!fieldsUser.password || fieldsUser.password.trim() === "") {
      formIsValid = false;
      errors.password = DashboardJSON.ErrorMsg.password;
    } else {
      const password = fieldsUser.password.trim();
      const passwordRegex = /^.{6,}$/; // Minimum 6 characters

      if (!passwordRegex.test(password)) {
        formIsValid = false;
        errors.password = "Password should be at least 6 characters long.";
      }
    }

    if (
      !fieldsUser.confirmPassword ||
      fieldsUser.confirmPassword.trim() === ""
    ) {
      formIsValid = false;
      errors.confirmPassword = DashboardJSON.ErrorMsg.confirmPassword;
    }
    if (fieldsUser.confirmPassword !== fieldsUser.password) {
      formIsValid = false;
      errors.confirmPassword = "Password Does not Match";
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const disableUser = (data) => {
    let userIdReq = {
      userId: data.userId,
    };
    let userPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };
    confirmAlert({
      title: `${data?.isDisable ? "Confirm to UnBlock User?" : "Confirm to Block User?"
        }`,
      message: `Are you sure you want to ${data?.isDisable ? "unblock" : "block"
        } ${data.userName}?`,

      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(userActions.updateUserStatus(userIdReq, userPageRefresh)),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const createGameSubmit4 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });

    setviewModal(true);
  };

  const deleteUser = (data) => {
    // console.log('datadatadatadatadatadatadatadatadata', data);

    let userIdReq = {
      userId: data._id,
    };

    let userPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };

    confirmAlert({
      title: "Confirm to Delete?",
      message: `Are you sure to delete ${data.userName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(userActions.deleteUser(userIdReq, userPageRefresh)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      keyWord: "",
      pageNo: data.selected + 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };
    dispatch(userActions.getUserList(pageReq));
  };

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    let pageReq = {
      keyWord: value,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
      roleId: 1,
    };
    dispatch(userActions.getUserList(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    setOffset(0);
    let pageReq = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: value,
      roleId: 1,
    };
    dispatch(userActions.getUserList(pageReq));
  };

  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };
  const handleEditUser = (url, element) => {
    navigate(url + element.userId);
  };
  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      // console.log(rowData)
      let obj = {
        userId: rowData?.userId,
        amount: rowData?.amount || 0,
      };

      console.log(rowData?.key === "Withdraw Fund");
      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj));
      }

      setrowData({});
      setviewModal(false);
    }
  };
  {
    /* {element.betStatus == 0 && <th scope="row" className="px-6 text-yellow-700 py-4 font-medium  whitespace-nowrap ">
    Pending
  </th>}
  {element.betStatus == 1 && <th scope="row" className="px-6 py-4 font-medium text-green-500 whitespace-nowrap ">
    Win
  </th>}
  {element.betStatus == 2 && <th scope="row" className="px-6 py-4 font-medium text-red-500 whitespace-nowrap ">
    Loss
  </th>}

  <td className="px-6 py-4 text-right">
    {element?.betStatus == 1 && <div className=' text-green-500'>{element?.winAmount || "--"}</div>}
    {element?.betStatus == 2 && <div className=' text-red-500'>{element?.lossAmount || "--"}</div>}
    {element?.betStatus == 0 && <div className=' text-yellow-700'>--</div>}

  </td> */
  }

  const handleFile = async (e) => {
    dispatch(userActions.uploadImage(e));
  };

  const handleRefList = async (data) => {
    let obj = {
      userId: data.userId,
    };
    dispatch(userActions.getreference(obj));
    setisOpen3((prev) => !prev);
  };

  let { users } = selector ? selector : {};
  let { loading, adminProfileDetails } = users ? users : {};
  let { getLedgerList, getLedgerTotal, crdt22 } = reports ? reports : {};
  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  let { } = users ? users : {};
  let { adminData } = adminProfileDetails ? adminProfileDetails : {};
  let { balance } = adminProfileDetails ? adminProfileDetails : {};

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">

                      <div className="bg-white rounded-xl border shadow">
                        <div className="flex justify-between items-center px-3 py-2 border-b">
                          <div className="flex gap-2 justify-between w-full items-end">
                            <div>
                              {localStoragedata &&
                                localStoragedata?.roleId == 0 && (
                                  <span className="text-xl font-bold whitespace-nowrap ">
                                    CLIENT
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="relative overflow-x-auto p-5">
                          <table className="w-full text-xs text-left rtl:text-right text-gray-500">
                            <thead className="text-slate-700 capitalize bg-gray-50">
                              <tr>
                                <th className="px-2 py-2">#</th>
                                {localStoragedata &&
                                  localStoragedata?.roleId == 20 ? (
                                  <th className="px-2 py-2">Username</th>
                                ) : (
                                  <th className="px-2 py-2">
                                    Username
                                  </th>
                                )}
                                <th className="px-2 py-2 whitespace-nowrap text-center">
                                  Credit Reference
                                </th>
                                {localStoragedata &&
                                  localStoragedata?.roleId == 20 ? (
                                  <>
                                    <th className="px-2 py-2">Balance</th>
                                    <th className="px-2 py-2">Exp</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="px-2 py-2">Balance</th>
                                    <th className="px-2 py-2">Exp</th>
                                    <th className="px-2 py-2">
                                      Downline Balance
                                    </th>
                                  </>
                                )}
                                <th className="px-2 py-2">Profit/Loss</th>
                                {localStoragedata &&
                                  localStoragedata?.roleId != 20 && (
                                    <th className="px-2 py-2">DownLine</th>
                                  )}
                                <th className="px-2 py-2 text-center">
                                  Block/Unblock
                                </th>
                                <th className="px-2 py-2 text-center">Edit</th>
                                <th className="px-2 py-2 whitespace-nowrap text-center">
                                  Point Statement
                                </th>
                                <th className="px-2 py-2 whitespace-nowrap text-center">
                                  Ledger
                                </th>
                                <th className="px-2 py-2">D / W</th>
                              </tr>
                            </thead>
                            <tbody>
                              {crdt22 && crdt22.length > 0 ? (
                                crdt22.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="bg-white border-b border-gray-400">
                                      <th
                                        scope="row"
                                        className="px-2 py-4 font-medium text-gray-500 whitespace-nowrap"
                                      >
                                        {offset + index + 1}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-2 py-4 font-medium text-black whitespace-nowrap capitalize"
                                      >
                                        {localStoragedata &&
                                          localStoragedata?.roleId == 20 ? (
                                          <div className="flex">
                                            {element?.userName || "-"}
                                          </div>
                                        ) : (
                                          <div className="flex">
                                            {element?.userName || "-"}{" "}
                                          </div>
                                        )}
                                      </th>

                                      <th className="px-2 py-4 font-medium text-gray-500 whitespace-nowrap"
                                      >
                                        <div className="flex justify-center space-x-3 items-center">
                                          <RiFileList2Line className="cursor-pointer w-4 h-4" onClick={() => handleRefList(element)} />
                                          <span>{element?.creditReference || "0"}</span>
                                          <BiEdit className="cursor-pointer w-4 h-4" title="Credit Reference" onClick={() => createGameSubmit4(element, "Credit Reference")} />
                                        </div>
                                      </th>

                                      {localStoragedata &&
                                        localStoragedata.roleId == 20 ? (
                                        <>
                                          <th className="px-2 py-3 font-medium text-gray-500 text-center">{element?.balance.toFixed(2) || "0"}</th>
                                          <th className="px-2 py-3 font-medium text-gray-500 text-center">{element?.exp.toString() || "0"}</th>
                                        </>
                                      ) : (
                                        <>
                                          <th className="px-2 py-3 font-medium text-gray-500 text-center">{element?.balance || "0"}</th>
                                          <th className="px-2 py-3 font-medium text-gray-500 text-center">{element?.exp.toString() || "0"}</th>
                                          <th className="px-2 py-3 font-medium text-gray-500 text-center">{element?.totalAmount || "0"}</th>
                                        </>
                                      )}

                                      {localStoragedata &&
                                        localStoragedata.roleId == 20 ? (
                                        <th className="px-2 py-3 font-medium text-gray-500 text-center">
                                          {Number(element.balance ?? 0) +
                                            Number(element.exp ?? 0) -
                                            Number(
                                              element.creditReference ?? 0
                                            )}
                                        </th>
                                      ) : (
                                        <th className="px-2 py-3 font-medium text-gray-500 text-center">
                                          {Number(element.balance ?? 0) +
                                            Number(element.exp ?? 0) +
                                            Number(element.totalAmount ?? 0) -
                                            Number(
                                              element.creditReference ?? 0
                                            )}
                                        </th>
                                      )}

                                      {localStoragedata &&
                                        localStoragedata?.roleId != 20 && (
                                          <td className="px-2 py-3">
                                            <div className="justify-center items-center flex">
                                              <FaStreetView onClick={() => navigate(`/app/${element?.userId}/${element?.userName}`)} className="w-5 h-5 text-[#7D1970] cursor-pointer" />
                                            </div>
                                          </td>
                                        )}

                                      <td className="px-2 py-3">
                                        <div className="justify-center items-center flex">
                                          <button className={element.isDisable === false ? "px-4 py-0.5 border border-green-700  bg-green-700 text-white rounded" : "px-4 py-0.5 border border-red-700 text-white bg-red-700 rounded"} onClick={() => disableUser(element)}>  {element.isDisable === false ? "Block" : "UnBlock"}</button>
                                        </div>
                                      </td>

                                      <th className="">
                                        <div className="flex justify-center items-center">
                                          <FiEdit onClick={() => handleEditUser("/app/EditUser/", element)} title="Edit" className="w-5 h-5 text-blue-600 cursor-pointer" />
                                        </div>
                                      </th>

                                      <th className="">
                                        <div className="flex justify-center items-center">
                                          <Link to={`/app/ledger/${element?.userId}?name=${element?.userName}`}>
                                            <HiClipboardList className="cursor-pointer w-6 h-6" />
                                          </Link>
                                        </div>
                                      </th>

                                      <th className="">
                                        <div className="flex justify-center items-center">
                                          <Link to={`/app/betsledger/${element?.userId}/${element?.userName}`}>
                                            <RiFileList3Fill title="Edit" className="cursor-pointer w-5 h-5" />
                                          </Link>
                                        </div>
                                      </th>

                                      <th className="px-2 py-3 font-base whitespace-nowrap">
                                        <div className="flex gap-3">
                                          <button className="bg-green-700 text-white px-3 py-1 text-xs cursor-pointer hover:shadow-md hover:duration-300 hover:transition-all ease-in-ou rounded" onClick={() => createGameSubmit4(element, "Add Fund")}>
                                            Deposit
                                          </button>
                                          <div
                                            className="bg-red-700 text-white px-3 py-1 text-xs cursor-pointer hover:shadow-md hover:duration-300 hover:transition-all ease-in-ou rounded"
                                            onClick={() =>
                                              createGameSubmit4(
                                                element,
                                                "Withdraw Fund"
                                              )
                                            }
                                          >
                                            Withdraw
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr className='bg-white'>
                                  <td colSpan={12} className='text-center py-3 font-semibold text-slate-800'>No Data Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateUserModal
        userOpenModal={userOpenModal}
        handleHideModal={handleHideModal}
        inputChange={inputChange}
        fieldsUser={fieldsUser}
        errorsUser={errorsUser}
        createUserSubmit={createUserSubmit}
        handleFile={handleFile}
        addRef={addRef}
      />

      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleFund={handleFund}
        errorsGame={errorsGame}
        handleChange={handleChange}
      />
      <ModelTest isOpen3={isOpen3} onClose3={onClose3} />
    </>
  );
};

export default UserSuperadmin;
