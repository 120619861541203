import { reportConstants } from "../_constants";

export default function reports(state = {}, action) {
  switch (action.type) {
    case reportConstants.GETBET_WISE_LEDGER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GETBET_WISE_LEDGER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getBetWiseReport: action?.report?.data,
      };
    case reportConstants.GETBET_WISE_LEDGER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_LEDFERS_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };


    case reportConstants.GET_BET_LISTSS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_BET_LISTSS_SUCCESS:
      return {
        ...state,
        loading: false,
        getBetWLists: action?.report?.data,
      };
    case reportConstants.GET_BET_LISTSS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        getGamePayments: action?.report?.data,
      };
    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS:
      return {
        ...state,
        loading: false,
        getGamePayments1: action?.report?.data,
      };
    case reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        getKarachiGamePayments: action?.report?.data,
      };
    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS:
      return {
        ...state,
        loading: false,
        getKarachiGamePayments1: action?.report?.data,
      };
    case reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case reportConstants.GET_LEDFERS_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };







    case reportConstants.GET_LEDFERS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getLedgerTotals: action?.report?.data?.total,
        getLedgerLists: action?.report?.data?.list,
      };
    case reportConstants.GET_LEDFERS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case reportConstants.GET_LEDFERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_LEDFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        crdt: action?.report?.data,
      };
    case reportConstants.GET_LEDFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };





    case reportConstants.GET_LEDFERS_REQUEST22:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_LEDFERS_SUCCESS22:
      return {
        ...state,
        loading: false,
        crdt22: action?.report?.data,
      };
    case reportConstants.GET_LEDFERS_FAILURE22:
      return {
        ...state,
        loading: false,
        error: action.error,
      };




    case reportConstants.GET_DOWN_LINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_DOWN_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        downLineData: action?.report?.data,
      };
    case reportConstants.GET_DOWN_LINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_ALL_ENTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_ALL_ENTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        getLedgerTotal: action?.report?.data?.total,
        getLedgerList: action?.report?.data?.list,
      };
    case reportConstants.GET_ALL_ENTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS:
      return {
        ...state,
        loading: false,
        numberListData: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.CLEAR_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isPopup: false,
        numberListData: null,
      };

    case reportConstants.GET_RESULT_DECLARE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_RESULT_DECLARE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        resultDataNum: action?.report?.data,
      };
    case reportConstants.GET_RESULT_DECLARE_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        getNumberKarachiWiseMarketSumOpen: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        getNumberKarachiWiseMarketSumClose: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        getNumberKarachiWiseMarketSumCenter: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        getNumberKarachiWiseMarketSumJodi: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        getNumberKarachiWiseMarketSumRing: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_SUCCESS:
      return {
        ...state,
        loading: false,
        isResultDeclare: false,
        isPopup: true,
        karachiResultDataNum: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };





    case reportConstants.GET_WIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_WIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        winList: action?.report?.data?.list,
        winTotal: action?.report?.data?.total,
        loading: false,
      };
    case reportConstants.GET_WIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        reportsList: action?.report?.data?.list,
        reportsTotal: action?.report?.data?.total,
        amountsPlaced: action?.report?.data?.amountsPlaced,
        totalWinAmount: action?.report?.data?.totalWinAmount,
        totalLossAmount: action?.report?.data?.totalLossAmount,
        loading: false,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        reportsKarachiList: action?.report?.data?.list,
        reportsKarachiTotal: action?.report?.data?.total,
        amountsKarachiPlaced: action?.report?.data?.amountsPlaced,
        totalKarachiWinAmount: action?.report?.data?.totalWinAmount,
        totalKarachiLossAmount: action?.report?.data?.totalLossAmount,
        loading: false,
      };
    case reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.DELETE_KARACHI_BETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.DELETE_KARACHI_BETS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case reportConstants.DELETE_KARACHI_BETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
