import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { useDispatch } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
// import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";

export default function ChangePasswordModal({ setopenmodel, openmodel }) {
  const [fieldslogin, setFieldsLogin] = useState({});
  const dispatch = useDispatch();

  const [errorslogin, setErrorsLogin] = useState({});

  // const handleOpen = () => setIsOpen(true);
  const handleClose = () => setopenmodel(false);
  let [datas, setdatas] = useState({});
  // const history = useHistory();
  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevState) => ({ ...prevState, [name]: value }));
    setErrorsLogin((prevState) => ({ ...prevState, [name]: '' }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      const data = {
        oldPassword: fieldslogin.oldPassword,
        newPassword: fieldslogin.password,
        confirmPassword: fieldslogin.confirmPassword,
      };
      dispatch(userActions.changePassword(data));
    }
  };

  const handleValidationLogin = () => {
    let errorslogin = {};
    let formIsValid = true;
    if (!fieldslogin.oldPassword || fieldslogin.oldPassword === '') {
      formIsValid = false;
      errorslogin.oldPassword = 'Old Password Cannot Be Blank.';
    }

    // Check New Password
    if (!fieldslogin.password || fieldslogin.password === '') {
      formIsValid = false;
      errorslogin.password = 'New Password Cannot Be Blank.';
    }
    if (!fieldslogin.password || fieldslogin.password.length<6) {
      formIsValid = false;
      errorslogin.password = 'New Password length must be between 6 ';
    }

    // Check Confirm Password
    if (!fieldslogin.confirmPassword || fieldslogin.confirmPassword === '') {
      formIsValid = false;
      errorslogin.confirmPassword = 'Confirm Password Cannot Be Blank.';
    } else if (fieldslogin.password !== fieldslogin.confirmPassword) {
      formIsValid = false;
      errorslogin.confirmPassword = 'Passwords and Confirm Password do not match.';
    }

    // Clear Confirm Password error if everything else is valid
    if (formIsValid) {
      errorslogin.confirmPassword = '';
    }

    setErrorsLogin(errorslogin);
    return formIsValid;
  };








  return (
    <div>

      {openmodel && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#911212] bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Change Password</h2>
            <form onSubmit={handleSubmit}>
              <div>

                <div className="form-group">
                  <label className="text-[#363063]  text-sm" for="oldpassword">Old Password *</label>
                  <input id="oldpassword"
                    className='w-full border border-gray-300 p-2.5 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded'
                    type="text"
                    name="oldPassword"
                    value={fieldslogin.oldPassword}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["oldPassword"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["oldPassword"]}
                    </div>
                  </div>
                  : null}
              </div>

              <div>

                <div className="form-group">
                  <label className="text-[#363063]  text-sm" for="newPassword">New Password *</label>
                  <input className='w-full border border-gray-300 p-2.5 focus:outline-none md:text-sm text-xs font-medium text-gray-400 rounded'
                    type="text"
                    name="password"
                    id="password"
                    placeholder='New Password'
                    value={fieldslogin.password}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["password"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["password"]}
                    </div>
                  </div>
                  : null}
              </div>
              <div>

                <div className="form-group ">
                  <label className="text-[#363063]  text-sm" for="confirmpassword">Confirm New Password *</label>
                  <input className='w-full border border-gray-300 p-2.5 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded'
                    type="text"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={fieldslogin.confirmPassword}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["confirmPassword"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["confirmPassword"]}
                    </div>
                  </div>
                  : null}
              </div>
              <div className="flex mt-2 items-center justify-between">
                <button
                  type="button"
                  onClick={handleClose}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
