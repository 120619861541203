import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import DashboardJSON from './UserManagement.json'
import { bankActions, userActions } from '../../_actions';
import CreateUserModal from "./components/CreateUserModal/CreateUserModal";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { IoChevronBackCircle } from "react-icons/io5";
import moment from 'moment'
import Loader from '../../components/Loader/Loader';
const UserInfo = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  const navigate = useNavigate();
  let [rowData, setRowData] = useState(null);
  let [userOpenModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUser, setFieldsUpdate] = useState({});
  const [errorsUser, setErrorsUpdate] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [size2, setSize2] = useState(10);
  const [size3, setSize3] = useState(10);
  const [offset2, setOffset2] = useState(0);
  const [pageNo2, setPageNo2] = useState(1);
  const [offset3, setOffset3] = useState(0);
  const [pageNo3, setPageNo3] = useState(1);
  // dsc
  const [sortBy, setsortBy] = useState("asc");
  const [sortBy2, setsortBy2] = useState("asc");
  const [sortBy3, setsortBy3] = useState("asc");
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [fromDate2, setfromDate2] = useState('');
  const [keyWord2, setkeyWord2] = useState('');
  const [toDate2, settoDate2] = useState('');
  const [keyWord, setkeyWord] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [fromDate3, setfromDate3] = useState('');
  const [keyWord3, setkeyWord3] = useState('');
  const [toDate3, settoDate3] = useState('');
  const { userList, userTotal } = useSelector(state => state.users);
  const params = useParams()

  useEffect(() => {
    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": pageNo,
      "size": size,
      userId: params?.userId
    }
    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: params?.userId
    }

    let myBetReq3 = {
      "fromDate": fromDate3,
      "toDate": toDate3,
      "keyWord": keyWord3,
      "sortBy": "",
      "sortOrder": sortBy3,
      "pageNo": pageNo3,
      "size": size3,
      userId: params?.userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))
    dispatch(bankActions.getClientBankDetail({ userId: params?.userId }));
    dispatch(userActions.getProfileforAdmin({ userId: params?.userId }));
    dispatch(userActions.transactionHistory(myBetReq2));
    dispatch(userActions.getLoginHistoryByUserId(myBetReq3));


  }, [params?.userId, keyWord]);


  const handleOpenModal = () => {
    setCreateModal(true);
  }

  const handleHideModal = (data) => {
    setCreateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const createUserSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUser()) {

      let userPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": size
      }

      const { userName, mobNo, email, password, confirmPassword } = fieldsUser;
      dispatch(userActions.registerUserByAdmin({
        userName: userName,
        mobNo: mobNo,
        email: email,
        password: password,
        confirmPassword: confirmPassword
      }));
      handleHideModal();

    }
  };

  const handleValidationUser = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName === "") {
      formIsValid = false;
      errors.userName = DashboardJSON.ErrorMsg.userName;
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo === "") {
      formIsValid = false;
      errors.mobNo = DashboardJSON.ErrorMsg.mobNo;
    }

    if (!fieldsUser.email || fieldsUser.email === "") {
      formIsValid = false;
      errors.email = DashboardJSON.ErrorMsg.email;
    }

    if (!fieldsUser.password || fieldsUser.password === "") {
      formIsValid = false;
      errors.password = DashboardJSON.ErrorMsg.password;
    }

    if (!fieldsUser.confirmPassword || fieldsUser.confirmPassword === "") {
      formIsValid = false;
      errors.confirmPassword = DashboardJSON.ErrorMsg.confirmPassword;
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };




  const deleteUser = (data) => {

    // console.log('datadatadatadatadatadatadatadatadata', data);

    let userIdReq = {
      "userId": data._id,
    }

    let userPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure you want  to delete ${data.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.deleteUser(userIdReq, userPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  const handlePageClick2 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage2(data.selected)
    // setSize2()
    setOffset2(offset);
    setPageNo2(data.selected+1);
    let myBetReq = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": data.selected + 1,
      "size": size2,
      userId: params?.userId
    }
    dispatch(userActions.transactionHistory(myBetReq))

  }



  const handlePageClick1 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected)
    setOffset(offset);
     setPageNo(data.selected+1);
    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": data.selected + 1,
      "size": size,
      userId: params?.userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))

  }



  const handlePageClick3 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage3(data.selected)
    setOffset3(offset);
    setPageNo3(data.selected+1);
    let myBetReq3 = {
      "fromDate": fromDate3,
      "toDate": toDate3,
      "keyWord": keyWord3,
      "sortBy": "",
      "sortOrder": sortBy3,
      "pageNo": data.selected + 1,
      "size": size3,
      userId: params?.userId
    }
    dispatch(userActions.getLoginHistoryByUserId(myBetReq3));

  }






  const handlePageClick = (data) => {

    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
     setPageNo(data.selected+1);
    let pageReq = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }

  }

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    let pageReq = {
      "keyWord": value,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }
    // dispatch(userActions.getUserList(pageReq));
  };

  const handleInputSize = (e) => {
    const newSize = e.target.value;
    setPageNo(1)
  
    setOffset(0)
    setCurrentPage(0)
    setSize(newSize)

  };

  const handleInputSize2 = (e) => {
    const newSize = e.target.value;
    setPageNo2(1)
    setOffset2(0)
    setCurrentPage2(0)
    setSize2(newSize)

  };

  const handleInputSize3 = (e) => {
    const newSize = e.target.value;
    setPageNo3(1)
    setOffset3(0)
    setCurrentPage3(0)
    setSize3(newSize)

  };


  const handleEditUser = (url, element) => {
    navigate(url + element._id);
  };




  const disableUser = (data,userDetailsById) => {

    let userIdReq = {
      "userId": params?.userId,
    }
    confirmAlert({

      title: userDetailsById?.isDisable?'Confirm to Disable?':'Confirm to Enable',
      message: userDetailsById?.isDisable?`Are you sure you want to Disable ${data}?`:`Are you sure you want to Enable ${data}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.updateUserStatus(userIdReq, null, params?.userId))

        },
        {
          label: 'No'
        }
      ]
    });
  }

  let handleInputSize22 = (e) => {

    setkeyWord2(e.target.value);
  }



  let handleInputSize1 = (e) => {

    setkeyWord(e.target.value);
  }
  let handleFilter1 = () => {
    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": pageNo,
      "size": size,
      userId: params?.userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))
  }
  let handleFilter2 = () => {
    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: params?.userId
    }
    dispatch(userActions.transactionHistory(myBetReq2));
  }

  let handleFilter3 = () => {
    let myBetReq3 = {
      "fromDate": fromDate3,
      "toDate": toDate3,
      "keyWord": keyWord3,
      "sortBy": "",
      "sortOrder": sortBy3,
      "pageNo": pageNo3,
      "size": size3,
      userId: params?.userId
    }
    dispatch(userActions.getLoginHistoryByUserId(myBetReq3));

  }

  useEffect(() => {
    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": pageNo,
      "size": size,
      userId: params?.userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))
  }, [keyWord, size])

  useEffect(() => {
    let myBetReq3 = {
      "fromDate": fromDate3,
      "toDate": toDate3,
      "keyWord": keyWord3,
      "sortBy": "",
      "sortOrder": sortBy3,
      "pageNo": pageNo3,
      "size": size3,
      userId: params?.userId
    }
    dispatch(userActions.getLoginHistoryByUserId(myBetReq3));
  }, [keyWord3, size3])

  useEffect(() => {
    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: params?.userId
    }
    dispatch(userActions.transactionHistory(myBetReq2));
  }, [keyWord2, size2])
  let { users, bank } = selector ? selector : {};
  let { userDetailsById, loading,betsListdata, betsListtotal, tnxList, tnxTotal,LoginSinglUSerList,LoginSinglUSertotal } = users ? users : {}
  let { bankDetailsByAdmin } = bank ? bank : {}
console.log(tnxList)


  return (
    <>
    <Loader loading={loading}/>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                    
                      <sectiion className="flex justify-between my-4 ">
                        <div className=' flex gap-2 items-center'>
                        <IoChevronBackCircle className=' text-2xl cursor-pointer' onClick={()=> navigate(-1)} /> <p className='text-2xl font-bold'>User Info</p>
                        </div>
                      </sectiion>

                      <div className='bg-white rounded-xl p-2'>
                        <div className=' flex gap-4 flex-wrap p-5 m-2 border'>
                          {/* Box one USer Details */}
                          <div className=' w-96 h-96 rounded-lg '>
                            <div>
                              <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
                                <div className="flex justify-end px-4 ">
                                  <button
                                    id="dropdownButton"
                                    data-dropdown-toggle="dropdown"
                                    className="inline-block text-gray-500   cursor-default focus:ring-4 focus:outline-none focus:ring-gray-200  rounded-lg text-sm p-1.5"
                                    type="button"
                                  >
                                    <span className="sr-only">Open dropdown</span>
                                    <svg
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      viewBox="0 0 16 3"
                                    >
                                    </svg>
                                  </button>

                                </div>
                                <div className="flex flex-col items-center pb-4">
                                  <img
                                    className="w-24 h-24 mb-3 rounded-full shadow-lg"
                                    src={userDetailsById?.image || "https://i.postimg.cc/zf4HQ1gZ/profile.png"}
                                    onError={(e) => e.target.src = "https://i.postimg.cc/zf4HQ1gZ/profile.png"}
                                    alt="Bonnie image"
                                  />
                                  <h5 className=" text-xl font-medium text-gray-900 ">
                                    {userDetailsById?.userName}
                                  </h5>
                                  <span className="text-sm text-gray-500 ">
                                    {userDetailsById?.email}
                                  </span>
                                  <span className="text-sm text-gray-500 ">
                                    +91 {userDetailsById?.mobNo}
                                  </span>
                                  <span className="text-sm text-gray-500 ">
                                  Wallet: {userDetailsById?.wallet||0}
                                  </span>
                                  <div className="flex mt-3 ">
                                    {userDetailsById?.isDisable ?
                                      <button onClick={() => disableUser(userDetailsById?.userName,userDetailsById)}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg"
                                      >
                                        Enable
                                      </button>
                                      :
                                      <button onClick={() => disableUser(userDetailsById?.userName,userDetailsById)}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg"
                                      >
                                        Disable
                                      </button>

                                    }


                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                          {/*Box two Bank Details   */}
                          <div className=' w-96 h-96 rounded-lg '>

                            <div

                              className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow  "
                            >
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                                Bank Details of  {userDetailsById?.userName || "--"}
                              </h5>
                              <div className=' flex flex-col gap-5 justify-around '>
                                <div className="font-normal flex justify-between  gap-3 text-gray-700 ">
                                  <div className=''>Name : </div><div className=' pr-5'>{bankDetailsByAdmin?.name || "--"}</div>
                                </div>

                                <div className="font-normal flex justify-between  gap-3 text-gray-700 ">
                                  <div className=''>Bank Name : </div><div className=' pr-5'>{bankDetailsByAdmin?.bankName || "--"}</div>
                                </div>


                                <div className="font-normal flex justify-between  gap-3 text-gray-700 ">
                                  <div className=''>Account Number : </div><div className=' pr-5'>{bankDetailsByAdmin?.accountNo || "--"}</div>
                                </div>

                                <div className="font-normal flex justify-between  gap-3 text-gray-700 ">
                                  <div className=''>IFSC : </div><div className=' pr-5'>{bankDetailsByAdmin?.ifsc || "--"}</div>
                                </div>
                                <div className="font-normal flex justify-between  gap-3 text-gray-700 ">
                                  <div className=''>Upi Id : </div><div className=' pr-5'>{bankDetailsByAdmin?.upiId || "--"}</div>
                                </div>




                              </div>

                            </div>






                          </div>
                        </div>
                        {/* <div className='flex justify-between items-center px-3 py-2 border-b'>
                          <span className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white">
                            User List
                          </span>
                          <span className="px-3 py-2 text-bas font-semibold text-white bg-gray-500 rounded-full">
                            Unapproved User List
                          </span>
                        </div> */}


                        {/* table One */}
                        <div className="relative m-2 overflow-x-auto p-5 border ">
                          <div className='flex justify-between items-center px-3 py-2 border-b'>
                            <div>
                              <div className=' text-gray-700 text-xl  font-bold'>Bid History</div>
                              <label class="block text-gray-700 text-base mb-2" for="name">Show Data</label>
                              <select className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="size" name="size" placeholder="name"
                                value={size} type="text"
                                onChange={(e) => handleInputSize(e)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>

                            </div>

                            <div className=" mt-1 shadow-sm">

                              <div className=' flex items-end gap-5 p-1  '>
                                <>
                                  <div>
                                    <div>
                                      From:
                                    </div>
                                    <input value={fromDate} onChange={(e) => setfromDate(e.target.value)} type='date' className=' outline-none  border p-2 rounded-md' />
                                  </div>

                                  <div>
                                    <div>

                                      To:
                                    </div>
                                    <input type='date' value={toDate} onChange={(e) => settoDate(e.target.value)} className=' outline-none  border p-2 rounded-md' />
                                  </div>
                                </>


                                <button onClick={() => handleFilter1()} className=' p-3 bg-[#911212] text-white rounded-lg'>Search</button>



                              </div>

                              <input className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="search" placeholder='Search' name="search" value={keyWord} type="text"
                                onChange={(e) => handleInputSize1(e)}
                              />
                              {errorsGame && errorsGame["search"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["search"]}
                                </div>
                                : null}
                            </div>
                          </div>
                          <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-base text-gray-700 capitalize  bg-gray-50">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Game Name</th>
                                <th className="px-6 py-3">Game Open Time</th>
                                <th className="px-6 py-3">Game Close Time</th>
                                <th className="px-6 py-3">Game Type</th>
                                <th className="px-6 py-3">Date</th>
                                <th className="px-6 py-3">Bet Number</th>
                                <th className="px-6 py-3">Point</th>
                                <th className="px-6 py-3">Bet Type</th>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Result</th>
                                <th className="px-6 py-3 text-right">Profit/Loss</th>
                              </tr>
                            </thead>
                            <tbody>
                              {betsListdata && betsListdata.length > 0 ? betsListdata.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b border-gray-300">

                                    <td className="px-6 py-4">
                                      {offset + index + 1}
                                    </td>


                                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                      {element && element?.gameId ? element?.gameId?.name : "-"}
                                    </th>
                                    <td className="px-6 py-4">
                                      {element && element?.gameId && element?.gameId?.openTime ? new Date(element?.gameId?.openTime).toLocaleTimeString() : "-"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element && element?.gameId && element?.gameId?.closingTime ? new Date(element?.gameId?.closingTime).toLocaleTimeString() : "-"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element && element?.marketId
                                        && element?.marketId
                                          ?.name ? element?.marketId?.name : "-"}
                                    </td>
                                    <td className="px-6 py-4 uppercase">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element && element.betNumber ? element.betNumber : "-"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element && element?.marketId?.point ? element?.marketId?.point : "-"}
                                    </td>

                                    <td className="px-6 py-4">
                                      {element && element.isOpen ? "Open" : "Close"}
                                    </td>

                                    <td className="px-6 py-4">
                                      {element && element.amount ? element.amount : "--"}
                                    </td>
                                    <td className="px-6 py-4">
                                      {element?.betStatus==0&&<div className=' text-yellow-500'>Pending</div>}
                                      {element?.betStatus==1&&<div className=' text-green-500'>Win</div>}
                                      {element?.betStatus==2&&<div className=' text-red-500'>Loss</div>}
                                     
                                    </td>

                                    <td className="px-6 py-4 text-right">
                                    {element?.betStatus==1&&<div className=' text-green-500'>{element?.winAmount||"--"}</div>}
                                    {element?.betStatus==2&&<div className=' text-green-500'>{element?.lossAmount||"--"}</div>}
                                    {element?.betStatus==0&&<div className=' text-yellow-500'>--</div>}
                                    
                                    </td>

                                  </tr>
                                </React.Fragment>
                              )) :
                                <tr>
                                  <td className="px-6 py-4">
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                           
                          </table>
                        </div>
                        {
                              betsListdata && betsListtotal > 10 ? (
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={Math.ceil(betsListtotal / size)}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={10}
                                  onPageChange={handlePageClick1}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                              ) : null
                            }

                        {/* Table 2 */}
                        <div className="relative m-2 overflow-x-auto p-5 border ">
                          <div className='flex justify-between items-center px-3 py-2 border-b'>
                            <div>
                              <div className=' text-gray-700 text-xl  font-bold'>Transaction History</div>
                              <label class="block text-gray-700 text-base mb-2" for="name">Show Data</label>
                              <select className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="size" name="size" placeholder="name"
                                value={size2} type="text"
                                onChange={(e) => handleInputSize2(e)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>

                            </div>

                            <div className=" mt-1 shadow-sm">

                              <div className=' flex items-end gap-5 p-1  '>
                                <>
                                  <div>
                                    <div>
                                      From:
                                    </div>
                                    <input value={fromDate2} onChange={(e) => setfromDate2(e.target.value)} type='date' className=' outline-none  border p-2 rounded-md' />
                                  </div>

                                  <div>
                                    <div>

                                      To:
                                    </div>
                                    <input type='date' value={toDate2} onChange={(e) => settoDate2(e.target.value)} className=' outline-none  border p-2 rounded-md' />
                                  </div>
                                </>


                                <button onClick={() => handleFilter2()} className=' p-3 bg-[#911212] text-white rounded-lg'>Search</button>



                              </div>

                              <select
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="transactionType"
                                name="transactionType"
                                value={keyWord2}
                                onChange={(e) => handleInputSize22(e)}
                              ><option value="">Select Type</option>
                                <option value="WITHDRAW">Withdrawal</option>
                                <option value="DEPOSIT">Deposit</option>
                              </select>

                              {errorsGame && errorsGame["search"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["search"]}
                                </div>
                                : null}
                            </div>
                          </div>
                          <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-base text-gray-700 capitalize  bg-gray-50">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Transaction type</th>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Type</th>
                                <th className="px-6 py-3">Date</th>

                              </tr>
                            </thead>
                            <tbody>
                              {tnxList && tnxList.length > 0 ? tnxList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b border-gray-300">

                                    <td className="px-6 py-4">
                                      {offset2 + index + 1}
                                    </td>


                                    <th className="px-6 py-4 font-medium whitespace-nowrap ">
                                      {element && element?.txtype ? element?.txtype : "-"}
                                    </th>
                                    <th className="px-6 py-4 font-medium  whitespace-nowrap ">
                                      {element && element?.amount ? element?.amount : "-"}
                                    </th>  <th className="px-6 py-4 font-medium  whitespace-nowrap ">
                                      {element && element?.type ? element?.type : "-"}
                                    </th>
                                    <td className="px-6 py-4 uppercase">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )) :
                                <tr>
                                  <td className="px-6 py-4">
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                           
                          </table>
                        </div>
                        {
                              tnxTotal && tnxTotal > size2 ? (
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={Math.ceil(tnxTotal / size2)}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={10}
                                  onPageChange={handlePageClick2}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage2}
                                />
                              ) : null
                            }
            
                        {/* Table 3 */}
                     
                        <div className="relative m-2 overflow-x-auto p-5 border ">
                          <div className='flex justify-between items-center px-3 py-2 border-b'>
                            <div>
                              <div className=' text-gray-700 text-xl  font-bold'>Login History</div>
                              <label class="block text-gray-700 text-base mb-2" for="name">Show Data</label>
                              <select className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="size" name="size" placeholder="name"
                                value={size3} type="text"
                                onChange={(e) => handleInputSize3(e)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>

                            </div>

                            <div className=" mt-1 shadow-sm">

                              <div className=' flex items-end gap-5 p-1  '>
                                <>
                                  <div>
                                    <div>
                                      From:
                                    </div>
                                    <input value={fromDate3} onChange={(e) => setfromDate3(e.target.value)} type='date' className=' outline-none  border p-2 rounded-md' />
                                  </div>

                                  <div>
                                    <div>

                                      To:
                                    </div>
                                    <input type='date' value={toDate3} onChange={(e) => settoDate3(e.target.value)} className=' outline-none  border p-2 rounded-md' />
                                  </div>
                                </>


                                <button onClick={() => handleFilter3()} className=' p-3 bg-[#911212] text-white rounded-lg'>Search</button>



                              </div>

                              {/* <select
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="transactionType"
                                name="transactionType"
                                value={keyWord2}
                                onChange={(e) => handleInputSize22(e)}
                              ><option value="">Select Type</option>
                                <option value="WITHDRAW">Withdrawal</option>
                                <option value="DEPOSIT">Deposit</option>
                              </select> */}

                              {errorsGame && errorsGame["search"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                  {errorsGame["search"]}
                                </div>
                                : null}
                            </div>
                          </div>
                          <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-base text-gray-700 capitalize  bg-gray-50">
                              <tr>
                              <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Device</th>
                                <th className="px-6 py-3">IP Address</th>
                                <th className="px-6 py-3">Status</th>
                                <th className="px-6 py-3">Date</th>

                              </tr>
                            </thead>
                            <tbody>
                              {LoginSinglUSerList && LoginSinglUSerList.length > 0 ? LoginSinglUSerList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b border-gray-300">

                                    <td className="px-6 py-4">
                                      {offset3 + index + 1}
                                    </td>


                                    <th className="px-6 py-4 font-medium  whitespace-nowrap ">
                                      {element && element?.device ? element?.device : "-"}
                                    </th>
                                    <th className="px-6 py-4 font-medium  whitespace-nowrap ">
                                      {element && element?.ipAddress ? element?.ipAddress : "-"}
                                    </th>  

                                    <th className="px-6 py-4 font-medium  whitespace-nowrap ">
                                      {element && element?.status  ? "Active" : "Inactive" || "--"}
                                    </th>  
                                    <td className="px-6 py-4 uppercase">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )) :
                                <tr>
                                  <td className="px-6 py-4">
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                        
                          </table>
                        </div>

                  
                            {
                              LoginSinglUSerList && LoginSinglUSertotal > size3 ? (
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={Math.ceil(LoginSinglUSertotal / size3)}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={10}
                                  onPageChange={handlePageClick3}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage3}
                                />
                              ) : null
                            }
                           


                      </div>
                    </div>
                  </div>



                </main>
              </div>
            </div>
          </div>
        </div>
      </div>


      <CreateUserModal
        userOpenModal={userOpenModal}
        handleHideModal={handleHideModal}
        inputChange={inputChange}
        fieldsUser={fieldsUser}
        errorsUser={errorsUser}
        createUserSubmit={createUserSubmit}
      />

      <ViewUserModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default UserInfo;
