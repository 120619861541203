import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { karachiGamesActions, gamesActions, karachiMarketActions } from '../../_actions';
import { MdOutlineNavigateNext } from "react-icons/md";
import { useParams } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { useTime } from 'react-timer-hook'


const KarachiPlaceBet = () => {
  const dispatch = useDispatch();
  const { games, karachiGame, karachiMarket } = useSelector(state => state) || {};
  const { sheetById } = games || {};
  const { karachiGamesList, karachiPlaceBet } = karachiGame || {};
  const { allKarachiMarket } = karachiMarket || {};

  const [arryofbets, setArryofbets] = useState([]);
  const [arryofbetsSheetLock, setArryofbetsSheetLock] = useState([]);
  const [isSheetLock, setSheetLock] = useState(false);
  const [isCurrentSheetLockDetails, setCurrentSheetLockDetails] = useState({});
  const { id, name } = useParams()
  let [localStoragedata, setlocalStoragedata] = useState();
  let [isDisable, setIsDisable] = useState(false);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [userName, setUserName] = useState({});
  const [gameName, setGameName] = useState({});
  const { subAdminData } = useSelector(state => state.games);
  const [isBetPrime, setIsBetPrime] = useState(false);
  const [gameNameRes, setGameNameRes] = useState(null);
  const [stateKey, setStateKey] = useState(null);
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const userPalyerName = games?.karachiPlaceBet && games?.karachiPlaceBet?.[0]?.userId && games?.karachiPlaceBet?.[0]?.userId?.userName ? games?.karachiPlaceBet?.[0]?.userId?.userName : userName && userName?.userName ? userName?.userName : null;

  // console.log('isBetPrime', isBetPrime);

  const handleKeyDown1 = (event) => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime((prevState) => !prevState); // Toggle the status
    }
  };

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
      }, 100);
    }
    const totalAmountRes = (karachiPlaceBet && karachiPlaceBet.length > 0)
      ? karachiPlaceBet.reduce((sum, record) => {
        if (['Single Open', 'Single Close'].includes(record?.localmarketname)) {
          return sum + (Number(record.amount) * 10) || 0;
        }
        if (['Single Kenchi', 'Double Kenchi'].includes(record?.localmarketname)) {
          return sum + (Number(record.amount) * record?.packet?.length) || 0;
        }
        return sum + Number(record.amount) || 0;
      }, 0)
      : 0;
    setTotalAmount(totalAmountRes)
  }, [karachiPlaceBet]);

  const [enterPressCount, setEnterPressCount] = useState(0); // Track Enter presses
  const betNumberInputRef = useRef(null);
  const amountInputRef = useRef(null); // Reference for the amount input

  const handleKeyDown = async (e, fieldName) => {
    console.log(enterPressCount, 'fieldName', fieldName, e.key);

    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
      return;
    }


    if (e.key === 'Enter') {
      e.preventDefault();

      if (['F9'].includes(stateKey) && enterPressCount === 0 && fieldName === 'betNumber') {
        amountInputRef.current?.focus();
        setEnterPressCount((prevCount) => prevCount + 1);
      }

      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        await handleSubmit();
        setEnterPressCount(0);
        betNumberInputRef.current?.focus();
        return;
      }

      if (enterPressCount === 0 && fieldName === 'betNumber') {
        amountInputRef.current?.focus();
        setEnterPressCount((prevCount) => prevCount + 1);
      }
      if (enterPressCount === 1) {
        await handleSubmit();
        setEnterPressCount(0);
        betNumberInputRef.current?.focus();
        return;
      }
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    try {
      const { betNumber, amount } = formData;

      if (errors.amount || errors.betNumber) {
        console.log("Please fix validation errors");
        return;
      }

      let packet = [];
      let isOpen = false;
      let marketId = currentOption
      if (['F9'].includes(stateKey)) {
        const resData = [{ ...formData, isOpen: isOpen, marketId, games: currentOption2, localmarketname: 'First Akda', isBetPrime: isBetPrime }]
        const gameId = fieldsGame && fieldsGame["gameId"] ? fieldsGame["gameId"] : id
        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null
        }
        const JodiRes = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
        for (const element of JodiRes) {
          // Map the bets for the current element
          const formattedBets = resData.map((games) => {
            return {
              userId: fieldsGame?.userId || null,
              betNumber: element,
              amount: games?.amount,
              marketId: games?.marketId?._id,
              gameId: gameId,
              isBetPrime: games?.isBetPrime,
              isOpen: games?.isOpen,
              localmarketname: 'First Akda',
              packet: [element],
            };
          });

          await dispatch(karachiGamesActions.placeBetByAdminRes(formattedBets, res));
        }

        setStateKey(null)
        setIsBetPrime(false);
        setFormData(prevData => ({
          ...prevData,
          betNumber: ''
        }));
      }
      if (marketId && currentOption2 && currentOption && betNumber && amount) {
        const marketData = (['Open'].includes(marketId?.toShow))
          ? (betNumber?.length === 1 ? 'Open' :
            betNumber?.length === 2 ? 'First Akda' : marketId?.toShow)
          : marketId?.toShow;
        if ((marketData === "Close" || marketData === "Open" || marketData === "Center") && betNumber?.length > 1) {
          return;
        }
        if ((marketData === "First TND" || marketData === "Second TND" || marketData === "FS Ring") && betNumber?.length > 3) {
          return;
        }
        if ((marketData === "First Akda" || marketData === "Second Akda") && betNumber?.length > 2) {
          return;
        }
        if (["Single Kenchi", "Double Kenchi"].includes(marketData) && (betNumber?.length < 3 || betNumber?.length > 7)) {
          return;
        }
        switch (marketData) {
          case "Open":
            packet = [betNumber];
            break;
          case "Close":
            packet = [betNumber];
            break;
          case "Center":
            packet = [betNumber];
            break;
          case "First TND":
            packet = [betNumber];
            break;
          case "Second TND":
            packet = [betNumber];
            break;
          case "First Akda":
            packet = [betNumber];
            break;
          case "Second Akda":
            packet = [betNumber];
            break;
          case "FS Ring":
            packet = [betNumber];
            break;
          case "Single Kenchi":
            packet = getUniqueTwoDigitCombinations(betNumber);
            break;
          case "Double Kenchi":
            packet = getTwoDigitCombinations(betNumber);
            break;
          default:
            break;
        }

        const resData = [{ ...formData, packet, isOpen: isOpen, marketId, games: currentOption2, localmarketname: marketData, isBetPrime: isBetPrime }]
        const gameId = fieldsGame && fieldsGame["gameId"] ? fieldsGame["gameId"] : id
        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null
        }
        const formattedBets = resData.map((games, ...bet) => {
          return ({
            userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : null,
            betNumber: games?.betNumber,
            amount: games?.amount,
            marketId: games?.marketId?._id,
            gameId: gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: games?.localmarketname,
            packet: games?.packet
          });
        })

        dispatch(karachiGamesActions.karachiPlaceBetByAdmin(formattedBets, res));

        setIsBetPrime(false);
        setFormData(prevData => ({
          ...prevData,
          betNumber: '' // Reset only the betNumber field
        }));
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
    }
  };

  // useEffect to add and clean up the event listener
  useEffect(() => {

    document.addEventListener('keydown', handleKeyDown1);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown1);
    };


  }, []);

  // useEffect to add and clean up the event listener
  useEffect(() => {
    if (!isDisable) {
      // Add the event listener
      window.addEventListener('keydown', handleKeyDown2);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('keydown', handleKeyDown2);
      };
    }
  }, [options, isDisable]);

  // useEffect to add and clean up the event listener
  useEffect(() => {
    if (!isDisable) {
      // Add the event listener
      window.addEventListener('keydown', handleKeyDown3);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('keydown', handleKeyDown3);
      };
    }
  }, [isDisable || fieldsGame]);

  useEffect(() => {
    setCurrentSheetLockDetails(sheetById ? sheetById : {});
    setArryofbetsSheetLock(sheetById?.bets || []);
  }, [sheetById]);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [currentIndex2, setCurrentIndex2] = useState(0);
  let [options2, setoptions2] = useState([])
  let currentOption2 = Array.isArray(options2) && options2.length > 0 ? options2[currentIndex2] : null;

  const [currentIndex, setCurrentIndex] = useState(0);
  let currentOption = Array.isArray(options) && options.length > 0 ? options[currentIndex] : null;


  useEffect(() => {

    if (name) {
      setGameNameRes(name)
    }

    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
    dispatch(karachiMarketActions.getAllKarachiMarket());
    dispatch(karachiGamesActions.getAllKarachiGames());
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      setIsDisable(true);
      dispatch(gamesActions.getSubAdminList())
    }
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(karachiGamesActions.getKarachiPlaceBetsByUserId({ gameId: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (fieldsGame?.gameId && fieldsGame?.userId) {
      dispatch(karachiGamesActions.getKarachiPlaceBetsByUserId({
        gameId: fieldsGame.gameId,
        userId: fieldsGame.userId
      }));
    }
  }, [fieldsGame, dispatch]);

  // Single Kachi: Unique Two-Digit Combinations
  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))];
    const combinations = [];

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j]);
        }
      }
    }
    return combinations;
  }

  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))];
    const combinations = [];

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j]);
      }
    }
    return combinations;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validate(name, value);

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }));

    if (name === 'betNumber') {
      setEnterPressCount(0)
    }
    if (name === 'amount') {
      setEnterPressCount(1)
    }


  };

  const validate = (name, value) => {
    let error = '';

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.';
      }
    } else if (name === 'betNumber') {
      const marketData = currentOption?.toShow;

      if ((marketData === 'Close' || marketData === 'Center') && value.length !== 1) {
        error = 'Only single digit allowed for Close/Center';
      } else if (['Single Kenchi', 'Double Kenchi'].includes(marketData) && (value.length < 3 || value.length > 7)) {
        error = 'Enter 3-7 digits for Kanchee types';
      } else if (['Second Akda'].includes(marketData) && value.length !== 2) {
        error = 'Akda requires exactly 2 digits';
      } else if (['First TND', 'Second TND', 'FS Ring'].includes(marketData) && value.length !== 3) {
        error = 'TND requires exactly 3 digits';
      } else if (['First Akda', 'Open'].includes(marketData) && value.length < 1 && value.length > 2) {
        error = 'First Akda requires exactly 2 digits';
      }
    }

    return error;
  };

  const handleDelete = (data) => {
    console.log('data', data);
    const res = {
      gameId: data?.karachiGameId?._id ?? null,
      userId: data?.userId._id ?? null
    }
    dispatch(karachiGamesActions.karachiPlaceBetByFreeze({ id: data._id }, res));
  };

  let isOpen = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Open")
  let isClose = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Close")
  let isCenter = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Center")
  let isSingleKahchee = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Single Kenchi")
  let isDoubalKahchee = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Double Kenchi")
  let isFirstAkda = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "First Akda")
  let isSecondAkda = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Second Akda")
  let isFirstTND = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "First TND")
  let isSecondTND = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "Second TND")
  let isFSRing = allKarachiMarket && allKarachiMarket?.length > 0 && allKarachiMarket.find(e => e?.name === "FS Ring")

  useEffect(() => {
    let data = [
      { ...isOpen, toShow: 'Open' },
      { ...isClose, toShow: 'Close' },
      { ...isCenter, toShow: 'Center' },
      { ...isSingleKahchee, toShow: 'Single Kenchi' },
      { ...isDoubalKahchee, toShow: 'Double Kenchi' },
      { ...isFirstAkda, toShow: 'First TND' },
      { ...isSecondAkda, toShow: 'Second TND' },
      // { ...isFirstTND, toShow: 'First Akda' },
      { ...isSecondTND, toShow: 'Second Akda' },
      { ...isFSRing, toShow: 'FS Ring' },
    ].filter(item => item !== null);
    setoptions(data)
    setoptions2(karachiGamesList)

  }, [stateKey, fieldsGame, gameNameRes, isOpen, isClose, isCenter, isSingleKahchee, isDoubalKahchee, isFirstAkda, isSecondAkda, isFirstTND, isSecondTND, isFSRing, karachiGamesList, games])

  const handleNext = (e) => {
    e.preventDefault()
    console.log('options', options);
    setCurrentIndex((prevIndex) => (prevIndex === options?.length - 1 ? 0 : prevIndex + 1));
  };

  const handleKeyDown2 = (event) => {
    const keyMap = {
      ArrowUp: 'Single Kenchi', // Move to Single Kenchi
      ArrowDown: 'Double Kenchi', // Move to Double Kenchi
      ArrowLeft: 'Open', // Move to Single Open
      ArrowRight: 'Close', // Move to Single Close
      F: 'First TND', // Move to First TND
      f: 'First TND', // Move to First TND
      S: 'Second TND', // Move to Second TND
      s: 'Second TND', // Move to Second TND
      C: 'Center', // Move to Center
      c: 'Center', // Move to Center
      F10: 'Second Akda', // Move to Second Akda
    };

    const action = keyMap[event.key];

    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'F', 'f', 'S', 's', 'C', 'c', 'F10'].includes(event.key)) {
      event.preventDefault();
    }

    if (action) {
      setCurrentIndex((prevIndex) => {
        const targetIndex = options.findIndex((item) => item.toShow === action);
        return targetIndex >= 0 ? targetIndex : prevIndex;
      });
    }
  };

  const handleKeyDown3 = (event) => {
    if (["F9"].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
    }
  };

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const inputChangeCreate = (e) => {
    e.preventDefault();
    setStateKey(null)
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));

    setArryofbets([]);

    if (name === 'userId') {
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
    }
    if (name === 'gameId') {
      const game = karachiGamesList?.find(ele => ele?._id === value)
      console.log('game==================', game);
      setGameNameRes(game?.name)
      setGameName(game)
    }
    if ((fieldsGame["userId"] && fieldsGame["gameId"]) || (name === 'userId' && fieldsGame["gameId"]) || (fieldsGame["userId"] && name === 'gameId')) {
      // dispatch(karachiGamesActions.getAllSheetLockUserId({ userId: fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : name === 'userId' ? value : null, gameId: fieldsGame && fieldsGame["gameId"] ? fieldsGame["gameId"] : name === 'gameId' ? value : id }));
      setIsDisable(false)
      setTimeout(() => {
        betNumberInputRef.current?.focus();
      }, 0);
    }

  };

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' });

  return (
    <div className="w-full bg-white h-screen overflow-hidden overflow-y-auto">
      <div className="relative bg-white">
        <div className="justify-between flex flex-col bg-white rounded-md border">

          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className="text-left bg-white space-y-2 rounded-md w-full p-4">
              <div className='w-full flex md:flex-row flex-col items-center justify-start gap-3'>
                <div className="md:w-56 w-full">
                  <label className="block text-gray-700 text-sm mb-1 font-medium" htmlFor="gameId">  Game Name:</label>
                  {karachiGamesList && karachiGamesList.length > 0 ? (
                    karachiGamesList.some((element) => {
                      const currentTime =
                        moment().hours() * 3600 +
                        moment().minutes() * 60 +
                        moment().seconds();
                      const openTime = element?.openTime;
                      const closingTime = element?.closingTime;

                      // Updated condition: current time should be within the range
                      return currentTime >= openTime && currentTime <= closingTime;
                    }) ? (
                      <select
                        className="md:w-56 w-full px-3 py-1.5 capitalize text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                        id="gameId"
                        name="gameId"
                        value={fieldsGame && fieldsGame['gameId'] ? fieldsGame['gameId'] : ''}
                        onChange={inputChangeCreate}

                      >
                        <option value="">Select Game Name</option>
                        {karachiGamesList.map((element, index) => {
                          const currentTime =
                            moment().hours() * 3600 +
                            moment().minutes() * 60 +
                            moment().seconds();
                          const openTime = element?.openTime;
                          const closingTime = element?.closingTime;

                          // Updated condition for filtering
                          if (currentTime >= openTime && currentTime <= closingTime) {
                            return (
                              <option key={index} value={element?._id || ""}>
                                {element && element?.name ? element?.name : ""}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                    ) : (
                      <div className="text-gray-500 text-sm">
                        No games are currently available for selection.
                      </div>
                    )
                  ) : (
                    <div className="text-gray-500 text-sm">No games available.</div>
                  )}

                  {errorsGame?.gameId && (
                    <div className="text-red-600 text-sm invalid-feedback">
                      {errorsGame["gameId"]}
                    </div>
                  )}
                </div>

                <div className="md:w-56 w-full">
                  <label class="block text-gray-700 text-sm mb-1" for="name">User Name :</label>
                  <select className="md:w-56 w-full px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none" id="userId" name="userId" placeholder="name" value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                    <option value="">Select User Name</option>
                    {subAdminData && subAdminData.length > 0 ? subAdminData.map((element, index) => (<option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}
                    </option>
                    )) : null}
                  </select>
                  {errorsGame && errorsGame["userId"] ?
                    <div className="text-red-600 invalid-feedback">{errorsGame["userId"]}</div> : null}
                </div>
              </div>
            </div>
          )}

          {/* isSheetLock table */}
          <div className="bg-gray-50 w-full px-4 pb-8">
            <div className='text-black capitalize text-xl w-full flex justify-between px-4 pt-3 pb-2'>
              <p>Karachi Bet Table</p>
              <p className='text-base'>Game Name-: <span className='text-xl font-semibold'>{gameNameRes}</span></p>
              <p className='text-base'>User Name-: <span className='text-xl font-semibold'>{userPalyerName}</span></p>
              <p className='text-base'>Total Amount-: <span className='text-xl font-semibold'>{totalAmount ?? 0}</span></p>
              <p className='text-base flex justify-between items-center space-x-2'>Timer-:
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'> {hours}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>{minutes}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>{seconds}</span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md uppercase space-x-2'>{ampm}</span>
              </p>
            </div>

            <div className='h-[33rem] overflow-x-auto overflow-y-auto' ref={scrollContainerRef}>
              <table className="min-w-full border border-gray-200">
                <thead className="sticky top-0 bg-gray-200 z-10">
                  <tr className="text-black font-semibold text-base">
                    <th className="p-2 uppercase tracking-wide border border-gray-300">#</th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className="p-2 uppercase tracking-wide border border-gray-300">User</th>
                        <th className="p-2 uppercase tracking-wide border border-gray-300">Game</th>
                      </>
                    )}
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Number</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Amount</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Market</th>
                    {/* <th className="p-2 uppercase tracking-wide border border-gray-300">Game</th> */}
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Packet</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Date/Time</th>
                    <th className="p-2 uppercase tracking-wide border border-gray-300">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {karachiPlaceBet && karachiPlaceBet?.length > 0 ? (
                    karachiPlaceBet.map((bet, index) => (
                      <tr key={index} className="border text-2xl last:border-none last:bg-green-300 bg-white text-gray-700">
                        <td className="p-2 border border-gray-200 text-center">{index + 1}</td>
                        {localStoragedata && localStoragedata?.roleId === 0 && (
                          <>
                            <td className="p-2 border border-gray-200 text-center">{bet?.userId?.userName ?? ''}</td>
                            <td className="p-2 font-medium border border-gray-200 text-center">{bet?.karachiGameId?.name ?? ''}</td>
                          </>
                        )}

                        {bet.isBetPrime ?
                          <>
                            <td className="p-2 text-red-600 border bordered-500 text-center font-bold">{bet.betNumber}</td>
                            <td className="p-2 text-red-600 border bordered-500 text-center font-bold">{['Single Open', 'Single Close'].includes(bet?.localmarketname) ? bet?.amount * 10 : bet?.amount}</td>
                          </>
                          :
                          <>
                            <td className="p-2  border border-gray-200 text-center font-bold">{bet.betNumber}</td>
                            <td className="p-2 border border-gray-200 text-center font-bold">{['Single Open', 'Single Close'].includes(bet?.localmarketname) ? bet?.amount * 10 : bet?.amount}</td>
                          </>
                        }
                        <td className="p-2 border border-gray-200 text-center">{bet?.localmarketname}</td>
                        {/* <td className="p-2 border border-gray-200">{bet.games?.name}</td> */}
                        <td className="p-2 border border-gray-200 text-center">{['Single Open', 'Single Close'].includes(bet?.marketId?.name) ? bet?.packet?.length * 10 : bet?.packet?.length}</td>
                        <td className="p-2 border border-gray-200 text-center">{moment(bet?.createdAt).format('DD-MM-YYYY HH:mm')}</td>
                        <td className="p-2 text-center">
                          <button
                            onClick={() => handleDelete(bet)}
                            className="text-red-500 hover:text-red-600 font-semibold text-base "
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='border border-gray-300'>
                      <td colSpan="8" className="text-center px-4 py-2 text-base text-gray-500 border border-gray-300">No bets available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>


          <form disabled={isDisable} className="p-4 bg-gray-100 rounded-t-lg shadow-md space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row lg:justify-between lg:items-center w-full z-10 flex flex-col justify-between items-center rounded-lg">
            <div className="flex flex-col lg:flex-row items-center gap-4 w-full">
              <div className="flex items-center gap-2">
                <div className="text-gray-800 border border-gray-300 rounded-lg px-4 py-2 w-full text-center">
                  {currentOption?.toShow}
                </div>
                <button
                  onClick={(e) => handleNext(e)}
                  disabled={isDisable}
                  type="button" // Set button to avoid form submission
                  className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-lg transition duration-300"
                >
                  <MdOutlineNavigateNext />
                </button>
              </div>

              {/* Bet Number Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="number"
                  ref={betNumberInputRef} // Reference for shifting focus back
                  value={['F9'].includes(stateKey) ? '11' : formData && formData.betNumber ? formData.betNumber : ''}
                  onChange={(e) => {
                    handleChange(e); // Call your existing handleChange function
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'betNumber')}
                  disabled={isDisable}
                  name="betNumber"
                  className="bg-white border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-100 text-gray-700 text-sm rounded-lg block w-full p-2.5 transition duration-300"
                  placeholder="Enter a number"
                  required
                />
                {errors.betNumber && <span className="text-red-500 text-xs mt-1">{errors.betNumber}</span>}
              </div>

              {/* Amount Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="number"
                  ref={amountInputRef} // Reference for shifting focus
                  value={formData && formData.amount ? formData.amount : ''}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  disabled={isDisable}
                  name="amount"
                  className="bg-white border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-100 text-gray-700 text-sm rounded-lg block w-full p-2.5 transition duration-300"
                  placeholder="Enter an amount"
                  required
                />
                {errors.amount && <span className="text-red-500 text-xs mt-1">{errors.amount}</span>}
              </div>
            </div>

          </form>


        </div>
      </div>
    </div>
  );
};

export default KarachiPlaceBet;