import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { gamesActions, reportActions, userActions } from "../../_actions";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

// import Table from '../../components/Atoms/Table/Table';

const UserBidHistory = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();

  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});

  // console.log('options.....', options);

  const {
    reportsList,
    reportsTotal,
    loading,
    amountsPlaced,
    totalWinAmount,
    totalLossAmount,
    downLineData,
  } = useSelector((state) => state.reports);
  const { userList, userTotal } = useSelector((state) => state.reports);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [Options, setOptions] = useState([]);
  const [toDate, settoDate] = useState("");
  const [selecteddata, setselecteddata] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame["Users"] || "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      userId: selecteddata,
    };

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame["Users"] || "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    dispatch(gamesActions.getAllGames(gameReq2));
    dispatch(reportActions.getBetsListForAdmin(gameReq));
    dispatch(reportActions.getDownLineUsers());
    dispatch(gamesActions.getAllMarket());
    dispatch(gamesActions.getAllUser());
  }, []);

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id,
      }));
      // console.log(options);
      setOptions(options);
    }
  }, [allUserList]);

  // console.log(Options);
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate2 = (e) => {
    console.log(e);
    setselecteddata(e);
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    console.log(fieldsGame);
    let gameReq2 = {
      fromDate: fieldsGame?.date || "",
      toDate: fieldsGame?.todate || "",
      keyWord: fieldsGame["Users"] || "",
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
      userId:
        selecteddata && selecteddata.length > 0
          ? selecteddata.map((e) => e.value)
          : [],
    };
    dispatch(reportActions.getBetsListForAdmin(gameReq2));
    // }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select Game Name";
    }

    if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
      formIsValid = false;
      errors.userId2 = "Select Game Type";
    }

    if (!fieldsGame.todate || fieldsGame.todate === "") {
      formIsValid = false;
      errors.todate = "Select Date";
    }

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Select Date";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {


    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage(data.selected);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame["Users"] || keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      userId:
        selecteddata && selecteddata.length > 0
          ? selecteddata.map((e) => e.value)
          : [],
    };

    dispatch(reportActions.getBetsListForAdmin(pageReq));
  };

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    let pageReq = {
      type: "WITHDRAW",
      keyWord: value,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: 100,
    };
    // dispatch(walletActions.getPaymentListForAdmin(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    let pageReq = {
      // "type": "WITHDRAW",
      keyWord: fieldsGame["Users"] || keyWord,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: value,
      userId:
        selecteddata && selecteddata.length > 0
          ? selecteddata.map((e) => e.value)
          : [],
    };
    dispatch(reportActions.getBetsListForAdmin(pageReq));
  };
  const [selectedValues, setSelectedValues] = useState([]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const handleDelete = (data) => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      userId: selecteddata,
    };

    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Delete Bid",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(userActions.DeleteBet(data, gameReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };
  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-4">

                      <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <div class="flex items-center bg-white border rounded-md overflow-hidden shadow">
                          <div class="px-4 py-2 text-gray-700">
                            <h3 class="text-sm tracking-wider">Total Amount Placed :</h3>
                            <p class="text-3xl"><span className="font-semibold textcolor">{(amountsPlaced && amountsPlaced.toString()) || ""}</span></p>
                          </div>
                        </div>
                        <div class="flex items-center bg-white border rounded-md overflow-hidden shadow">
                          <div class="px-4 py-2 text-gray-700">
                            <h3 class="text-sm tracking-wider">Total Win Amount :</h3>
                            <p class="text-3xl"><span className="font-semibold textcolor">{(totalWinAmount && totalWinAmount.toString()) || "0"}</span></p>
                          </div>
                        </div>
                        <div class="flex items-center bg-white border rounded-md overflow-hidden shadow">
                          <div class="px-4 py-2 text-gray-700">
                            <h3 class="text-sm tracking-wider">Total Loss Amount : </h3>
                            <p class="text-3xl"><span className="font-semibold textcolor">{(totalLossAmount && totalLossAmount.toString()) || "0"}</span></p>
                          </div>
                        </div>
                      </div>



                      <div className="bg-white p-2 text-left rounded-lg">

                        <div className="grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                          <div className="w-full">
                            <label
                              class="block text-gray-700 text-xs font-semibold mb-1"
                              for="gamename"
                            >
                              From Date :
                            </label>
                            <input
                              className="w-full px-3 py-1.5 text-xs bg-white border border-gray-400 rounded focus:outline-none uppercase"
                              id="date"
                              name="date"
                              placeholder="Enter date"
                              value={
                                fieldsGame && fieldsGame["date"]
                                  ? fieldsGame["date"]
                                  : ""
                              }
                              max={today}
                              type="date"
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame["date"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="w-full">
                            <label
                              class="block text-gray-700 text-xs font-semibold mb-1"
                              for="gamename"
                            >
                              To Date :
                            </label>
                            <input
                              className="w-full px-3 py-1.5 text-xs bg-white border border-gray-400 rounded focus:outline-none uppercase"
                              id="date"
                              name="todate"
                              placeholder="Enter date"
                              value={
                                fieldsGame && fieldsGame["todate"]
                                  ? fieldsGame["todate"]
                                  : ""
                              }
                              max={today}
                              type="date"
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame["todate"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["todate"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="w-full">
                            <label
                              class="block text-gray-700 text-xs font-semibold mb-1"
                              for="name"
                            >
                              Game Name :
                            </label>
                            <select
                              className="w-full px-3 py-2 capitalize text-xs bg-white border border-gray-400 rounded focus:outline-none"
                              id="userId"
                              name="userId"
                              placeholder="name"
                              value={
                                fieldsGame && fieldsGame["userId"]
                                  ? fieldsGame["userId"]
                                  : ""
                              }
                              type="text"
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select Game Name</option>
                              {gamesList && gamesList.length > 0
                                ? gamesList.map((element, index) => (
                                  <option
                                    value={
                                      element && element._id
                                        ? element._id
                                        : ""
                                    }
                                  >
                                    {element && element.name
                                      ? element.name
                                      : ""}
                                  </option>
                                ))
                                : null}
                            </select>
                            {errorsGame && errorsGame["userId"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="w-full">
                            <label
                              class="block text-gray-700 text-xs font-semibold mb-1"
                              for="name"
                            >
                              Game Type :
                            </label>
                            <select
                              className="w-full px-3 py-2 text-xs bg-white border border-gray-400 rounded focus:outline-none capitalize"
                              id="userId2"
                              name="userId2"
                              placeholder="name"
                              value={
                                fieldsGame && fieldsGame["userId2"]
                                  ? fieldsGame["userId2"]
                                  : ""
                              }
                              type="text"
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select Game Type</option>
                              {allMarket && allMarket.length > 0
                                ? allMarket.map((element, index) => (
                                  <option
                                    value={
                                      element && element._id
                                        ? element._id
                                        : ""
                                    }
                                  >
                                    {element && element.name
                                      ? element.name
                                      : ""}
                                  </option>
                                ))
                                : null}
                            </select>
                            {errorsGame && errorsGame["userId2"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId2"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="w-full">
                            <label
                              class="block text-gray-700 text-xs font-semibold mb-1"
                              for="name"
                            >
                              User :
                            </label>
                            <select
                              className="w-full px-3 py-2 text-xs bg-white border border-gray-400 rounded focus:outline-none"
                              id="Users"
                              name="Users"
                              placeholder="name"
                              value={
                                fieldsGame && fieldsGame["Users"]
                                  ? fieldsGame["Users"]
                                  : ""
                              }
                              type="text"
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select User</option>
                              {downLineData && downLineData.length > 0
                                ? downLineData.map((element, index) => (
                                  <option
                                    value={
                                      element && element._id
                                        ? element._id
                                        : ""
                                    }
                                  >
                                    {element && element.userName
                                      ? element.userName
                                      : ""}
                                  </option>
                                ))
                                : null}
                            </select>
                            {errorsGame && errorsGame["Users"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["Users"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                            <button className="flex w-full lg:w-auto justify-center px-4 py-1.5 text-base font-semibold text-white capitalize transition duration-150 ease-in-out bgcolor rounded focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                        </div>
                      </div>

                      <div className="text-left bg-white space-y-1 rounded-lg">
                        <div className="flex justify-between items-center px-6 border-b">
                          <span className=" text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white">Bets History List</span>
                          <div className="flex justify-between items-center gap-1 px-3 py-2">
                            <label class="block text-gray-700 text-xs" for="name">Show</label>
                            <select
                              className={`w-full px-3 py-1 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                              id="size"
                              name="size"
                              placeholder="name"
                              value={
                                fieldsGame && fieldsGame["size"]
                                  ? fieldsGame["size"]
                                  : ""
                              }
                              type="text"
                              onChange={handleInputSize}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                        <div className="relative overflow-x-auto">
                          <table className="w-full text-xs text-left rtl:text-right text-gray-500 shadow">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr className="whitespace-nowrap">
                                <th className="px-4 py-3">#</th>
                                <th className="px-4 py-3">user name</th>
                                <th className="px-4 py-3">amount</th>
                                <th className="px-4 py-3">Bet Number</th>
                                <th className="px-4 py-3">Game Name</th>
                                <th className="px-4 py-3">Market Name</th>
                                <th className="px-4 py-3">Bid Type</th>
                                <th className="px-4 py-3">Date</th>
                                <th className="px-4 py-3">Bet Status</th>
                                <th className="px-4 py-3">Profit/Loss</th>
                                <th className="px-4 py-3">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportsList && reportsList.length > 0 ? (
                                reportsList.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr key={index} className="bg-white border-b border-gray-400 capitalize">
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap ">  {offset + index + 1}</th>
                                      <th className="px-4 py-2 font-semibold text-[#992941] whitespace-nowrap ">
                                        <Link to={`/app/user/${element.userId?._id}`}>{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</Link>
                                      </th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.amount ? element.amount : "-"}
                                      </th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.betNumber ? element.betNumber : "-"}</th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.marketId && element.marketId.name ? element.marketId.name : "-"}</th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.isOpen && element.isOpen ? "Open" : "Close"}</th>
                                      <th className="px-4 py-2 font-medium text-gray-500 whitespace-nowrap">{element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD hh:mm:ss a") : "-"}</th>
                                      {element.betStatus == 0 && (
                                        <th className="px-4 text-yellow-600 py-2 font-medium  whitespace-nowrap">Pending</th>
                                      )}
                                      {element.betStatus == 1 && (
                                        <th className="px-4 py-2 font-medium text-green-500 whitespace-nowrap">Win</th>
                                      )}
                                      {element.betStatus == 2 && (
                                        <th className="px-4 py-2 font-medium text-red-500 whitespace-nowrap">Loss</th>
                                      )}

                                      <td className="px-4 py-2 text-center">
                                        {element?.betStatus == 1 && (
                                          <div className=" text-green-500">
                                            {element?.winAmount || "--"}
                                          </div>
                                        )}
                                        {element?.betStatus == 2 && (
                                          <div className=" text-red-500">
                                            {element?.lossAmount || "--"}
                                          </div>
                                        )}
                                        {element?.betStatus == 0 && (
                                          <div className=" text-yellow-700">
                                            --
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {element?.betStatus == 0 &&
                                          sessiondata &&
                                          sessiondata.roleId == 0 && (
                                            <MdDelete
                                              onClick={() =>
                                                handleDelete(element)
                                              }
                                              className=" text-red-600 text-lg mx-auto cursor-pointer"
                                            />
                                          )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className="relative z-0 flex justify-end py-4 w-76">
                          {reportsTotal && reportsTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={Math.ceil(reportsTotal / size)} // Calculate total pages
                              marginPagesDisplayed={1} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBidHistory;
