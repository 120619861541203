import { karachiMarketConstants } from '../_constants';
import { alertActions } from './alert.actions';
import {
    APIcallFunction,
    //  headerForPublicAPI, logoutFunction,
    headerForPrivateAPI, APIcallFunctionForImage, headerForPrivateMediaAPI
} from '../_helpers';
export const karachiMarketActions = {
    createKarachiMarket,
    getKarachiMarketList,
    getAllKarachiMarket,
    updateKarachiMarket,
    updateKarachiMarketStatus,
    getKarachiMarketByKarachiMarketIdForAdmin,
    deleteKarachiMarket,
    uploadImageApi,
    // uploadImage2,
    uploadImageClear,
};

function createKarachiMarket(data, setCreateModal, marketPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createKarachiMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getKarachiMarketList(marketPageRefresh));
                    setCreateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.CREATE_KARACHI_MARKET_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.CREATE_KARACHI_MARKET_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.CREATE_KARACHI_MARKET_FAILURE, error } }
}
function getKarachiMarketList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getKarachiMarketList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_LIST_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_LIST_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_LIST_FAILURE, error } }
}

function updateKarachiMarket(data, navigate
    // , marketPageRefresh
) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateKarachiMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(alertActions.success("Updated"));
             
     
               
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_FAILURE, error } }
}

function updateKarachiMarketStatus(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateKarachiMarketStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getKarachiMarketList(userPageRefresh));
                    dispatch(alertActions.success("KarachiMarket Status Update Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.UPDATE_KARACHI_MARKET_STATUS_FAILURE, error } }
}

function getKarachiMarketByKarachiMarketIdForAdmin(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getKarachiMarketByKarachiMarketIdForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    console.log('marketACTION_____?', market);

                    dispatch(success(market));
                    // dispatch(getKarachiMarketList(userPageRefresh));
                    // dispatch(alertActions.success("KarachiMarket Status Update Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.GET_KARACHI_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE, error } }
}

function deleteKarachiMarket(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteKarachiMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getKarachiMarketList(userPageRefresh));
                    dispatch(alertActions.success("KarachiMarket delete Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.DELETE_KARACHI_MARKET_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.DELETE_KARACHI_MARKET_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.DELETE_KARACHI_MARKET_FAILURE, error } }
}

function getAllKarachiMarket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllKarachiMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: karachiMarketConstants.GET_ALL_KARACHI_MARKET_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.GET_ALL_KARACHI_MARKET_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.GET_ALL_KARACHI_MARKET_FAILURE, error } }
}

function uploadImageApi(filedata) {
    console.log('uploadImageuploadImage????', filedata);
    var formdata = new FormData();
    formdata.append('image', filedata);
    console.log('vvvvvvvvvvvvvvvvvvvv', formdata);
    const credentials = {
        header: headerForPrivateMediaAPI,
        method: "POST",
        body: formdata,
        endPoint: '/upload'
    }
    return dispatch => {
        // dispatch(request({ data }));
        APIcallFunctionForImage(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    // function request(market) { return { type: karachiMarketConstants.FILE_UPLOAD_STATUS_REQUEST, market } }
    function success(market) { return { type: karachiMarketConstants.FILE_UPLOAD_STATUS_SUCCESS, market } }
    function failure(error) { return { type: karachiMarketConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: karachiMarketConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}