import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { FaHome } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdNotificationsNone } from "react-icons/md";
import { MdHistoryEdu } from "react-icons/md";
import { TfiWallet } from "react-icons/tfi";
import { TbSettingsUp } from "react-icons/tb";
import { GiCardRandom } from "react-icons/gi";
import { PiDotFill } from "react-icons/pi";
import { TbBuildingWarehouse } from "react-icons/tb";
import { FaUserShield } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";


function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center text-gray-500 focus:outline-none focus:bg-gray-800 z-50 h-16`}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"  ></path>
        </svg>
      </button>


      <div
        className={`${navbarOpen === false ? "hidden " : "flex "
          } fixed left-0 inset-y-0 w-64 z-50 flex-col`}
      >
        <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">

          <div className="cursor-pointer relative flex justify-between place-items-center border-b-2 flex-shrink-0 bg-[#911212] w-full z-50">
            <img src='/Imgpshlogo.png' alt="" className="h-[64px] mx-auto w-[90px] items-center md:ml-6" />
            <div className="">
              <GiHamburgerMenu onClick={handleNavbar} className="text-white text-xl block mx-4" />
            </div>
          </div>

          <nav className="overflow-y-auto bgcolor flex flex-col flex-wrap items-center justify-between relative w-64 z-10 h-full overflow-auto zilla-slab-medium">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() => onClickMenu("/app/dashboard")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/dashboard"
                      ? "textcolor bgwhite font-semibold"
                      : "textwhite font-semibold texthover"
                      }`}
                  >
                    <span className="group texthover">
                      <FaHome size={18} />
                    </span>
                    <span className="whitespace-nowrap">Dashboard</span>
                  </span>


                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() => onClickMenu("/app/winnerList")}
                      className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/winnerList"
                        ? "textcolor bgwhite font-semibold "
                        : "textwhite font-semibold texthover"
                        }`}
                    >
                      <span className="group texthover">
                        <GiAngelWings size={18} />
                      </span>
                      <span className="whitespace-nowrap">Winner List</span>
                    </span>
                  )}



                  {localStoragedata && localStoragedata.roleId == 0 && <span
                    onClick={() => onClickMenu("/app/downsideUser")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/downsideUser"
                      ? "textcolor bgwhite font-semibold"
                      : "text-white font-semibold texthover"
                      }`}
                  >
                    <span className="group texthover">
                      <FaUserShield size={18} />
                    </span>
                    {localStoragedata && localStoragedata.roleId == 0 && (
                      <span className="whitespace-nowrap">ADMIN</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 1 && (
                      <span className="whitespace-nowrap">SUPER MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 2 && (
                      <span className="whitespace-nowrap">MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 3 && (
                      <span className="whitespace-nowrap">AGENT</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 4 && (
                      <span className="whitespace-nowrap">OPERATOR</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 20 && (
                      <span className="whitespace-nowrap">CLIENT</span>
                    )}
                  </span>}


                  {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/userofSuperadmin')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 font-semibold   ${location.pathname === "/app/userofSuperadmin" ? "textcolor bgwhite font-semibold" : "textwhite font-semibold texthover"}`}>
                    <span className="group-hover:text-[#D8BD66]"><FaUsers size={18} /></span>
                    <span className="whitespace-nowrap">User Mgmt</span>
                  </span>}


                  {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/notification')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 font-semibold   ${location.pathname === "/app/notification" ? "textcolor bgwhite font-semibold" : "textwhite font-semibold texthover"}`}>
                    <span className="group-hover:text-[]"><MdNotificationsNone size={18} /></span>
                    <span className="whitespace-nowrap">Notification Mgmt</span>
                  </span>}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                        >
                          <div className="flex space-x-4 items-center">
                            <span className="group texthover">
                              <TbSettingsUp size={18} />
                            </span>
                            <span className="whitespace-nowrap">
                              Setting Management
                            </span>
                          </div>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="h-5 w-5" />
                          ) : (
                            <HiChevronDown className="h-5 w-5" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                        >
                          <div className="flex space-x-4 items-center">
                            <span className="group texthover">
                              <TbSettingsUp size={18} />{" "}
                            </span>
                            <span className="text-base group texthover whitespace-nowrap">
                              Setting Mgmt
                            </span>
                          </div>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group texthover" />
                          ) : (
                            <HiChevronDown className="group texthover" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="flex flex-col">
                          <span
                            onClick={() => onClickMenu("/app/appsetting")}
                            className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/appsetting"
                              ? "textcolor bgwhite font-semibold"
                              : "textwhite font-semibold"
                              }`}
                          >
                            <PiDotFill className="w-6 h-6" />{" "}
                            <span className="text-sm whitespace-nowrap">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() => onClickMenu("/app/slider")}
                            className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/slider"
                              ? "textcolor bgwhite font-semibold"
                              : "textwhite font-semibold"
                              }`}
                          >
                            <PiDotFill className="w-6 h-6" />{" "}
                            <span className="text-sm whitespace-nowrap">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>

                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <LuGamepad2 size={18} />
                          </span>
                          <span className="whitespace-nowrap">
                            Game Mgmt
                          </span>
                        </div>
                        {showSection === "game" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <LuGamepad2 size={18} />{" "}
                          </span>
                          <span className="whitespace-nowrap">
                            Game Mgmt
                          </span>
                        </div>
                        {showSection === "game" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    )}
                    {showSection === "game" ? (
                      <div className="flex flex-col">
                        <span
                          onClick={() => onClickMenu("/app/game")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/game"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">Game </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/market")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/market"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Market Management
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/declareResult")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/declareResult"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Game Result
                          </span>
                        </span>

                        {/* <span
                          onClick={() => onClickMenu("/app/betHistory")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/betHistory"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Bets Point History
                          </span>
                        </span> */}

                        <span
                          onClick={() =>
                            onClickMenu("/app/userbidhistory")
                          }
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/userbidhistory"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">User Bet History </span>
                        </span>


                      </div>
                    ) : null}
                  </div>
                )}


                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "karachi-game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <LuGamepad2 size={18} />
                          </span>
                          <span className="whitespace-nowrap">
                            Karachi Game Mgmt
                          </span>
                        </div>
                        {showSection === "karachi-game" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("karachi-game")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <LuGamepad2 size={18} />{" "}
                          </span>
                          <span className="whitespace-nowrap">
                            Karachi Game Mgmt
                          </span>
                        </div>
                        {showSection === "karachi-game" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    )}
                    {showSection === "karachi-game" ? (
                      <div className="flex flex-col">
                        <span
                          onClick={() => onClickMenu("/app/karachi-game")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/karachi-game"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">Karachi Game </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/karachi-Management")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/karachi-Management"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Karachi Market Mgmt
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/karachi-declare-result")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/karachi-declare-result"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Karachi Game Result
                          </span>
                        </span>

                        {/* <span
                          onClick={() => onClickMenu("/app/betHistory")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/betHistory"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">
                            Karachi Bets Point History
                          </span>
                        </span> */}

                        <span
                          onClick={() =>
                            onClickMenu("/app/user-karachi-bid-history")
                          }
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/user-karachi-bid-history"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm whitespace-nowrap">Karachi User Bet History </span>
                        </span>


                      </div>
                    ) : null}
                  </div>
                )}






                {localStoragedata && localStoragedata.roleId == 0 &&
                  <div className="cursor-pointer group">
                    {showSection === "walletMangment" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <TfiWallet size={18} />
                          </span>
                          <span className="whitespace-nowrap">
                            Wallet Mgmt
                          </span>
                        </div>
                        {showSection === "walletMangment" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("walletMangment")}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite"
                      >
                        <div className="flex space-x-4 items-center">
                          <span className="group texthover">
                            <TfiWallet size={18} />{" "}
                          </span>
                          <span className="whitespace-nowrap">
                            Wallet Mgmt
                          </span>
                        </div>
                        {showSection === "walletMangment" ? (
                          <HiChevronUp className="group texthover" />
                        ) : (
                          <HiChevronDown className="group texthover" />
                        )}
                      </span>
                    )}
                    {showSection === "walletMangment" ? (
                      <div className="flex flex-col">
                        <span
                          onClick={() => onClickMenu("/app/AddFundRequest")}
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/AddFundRequest"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm  whitespace-nowrap">Add Fund Report </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/withdrawRequest")
                          }
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/withdrawRequest"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >
                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm  whitespace-nowrap">Withdraw Fund Report</span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/addFund")
                          }
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/addFund"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >

                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm  whitespace-nowrap">Add Fund </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/withdrawfund")
                          }
                          className={`flex items-center px-4 py-1 my-1 w-full text-sm font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover space-x-4  ${location.pathname === "/app/withdrawfund"
                            ? "textcolor bgwhite font-semibold"
                            : "textwhite font-semibold"
                            }`}
                        >

                          <PiDotFill className="w-6 h-6" />{" "}
                          <span className="text-sm  whitespace-nowrap">Withdraw Fund </span>
                        </span>


                      </div>
                    ) : null}
                  </div>
                }

                {localStoragedata && localStoragedata.roleId === 0 && <span
                  onClick={() =>
                    onClickMenu("/app/placebet")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/placebet"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <GiCardRandom size={18} />{" "}
                  <span className="whitespace-nowrap">Place Bet</span>
                </span>}

                <span
                  onClick={() =>
                    onClickMenu("/app/betNumber")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/betNumber"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <MdHistoryEdu size={18} />{" "}
                  <span className="whitespace-nowrap">Games Chart</span>
                </span>

                <span
                  onClick={() =>
                    onClickMenu("/app/user-profit-loss")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/user-profit-loss"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <MdHistoryEdu size={18} />{" "}
                  <span className="whitespace-nowrap">Games Profit Loss </span>
                </span>


                {localStoragedata && localStoragedata.roleId === 0 && <span
                  onClick={() =>
                    onClickMenu("/app/KarachiPlaceBet")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/KarachiPlaceBet"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <GiCardRandom size={18} />{" "}
                  <span className="whitespace-nowrap">Karachi Place Bet</span>
                </span>}

                {/* { localStoragedata && localStoragedata.roleId !== 0 &&<span
                  onClick={() =>
                    onClickMenu("/app/ledger")
                  }
                  className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/ledger"
                    ? "textcolor bgwhite font-semiboldrounded-md"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <MdHistoryEdu size={18} />{" "}
                  <span className="whitespace-nowrap">My Statements </span>
                </span>} */}


                {localStoragedata && localStoragedata.roleId !== 0 &&
                  <span
                    onClick={() =>
                      onClickMenu("/app/mybets")
                    }
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/mybets"
                      ? "textcolor bgwhite font-semibold"
                      : "texthover textwhite font-semibold"
                      }`}
                  >
                    <MdHistoryEdu size={18} />{" "}
                    <span className="whitespace-nowrap">My Bets </span>
                  </span>

                }




                <span
                  onClick={() =>
                    onClickMenu("/app/karachi")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/karachi"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <TbBuildingWarehouse size={18} />{" "}
                  <span className="whitespace-nowrap">Karachi Chart</span>
                </span>

                <span
                  onClick={() =>
                    onClickMenu("/app/karachi-profit-loss")
                  }
                  className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === "/app/karachi-profit-loss"
                    ? "textcolor bgwhite font-semibold"
                    : "texthover textwhite font-semibold"
                    }`}
                >
                  <MdHistoryEdu size={18} />{" "}
                  <span className="whitespace-nowrap">Karachi Profit Loss </span>
                </span>


              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
