import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./WalletManagement.json";
import { gamesActions, reportActions } from "../../_actions";
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import moment from "moment";
// import Select from 'react-select'
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

// import Table from '../../components/Atoms/Table/Table';

const WinList = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  // const today = new Date().toISOString().split('T')[0];

  // console.log('options.....', options);
  const { winList, winTotal, loading } = useSelector((state) => state.reports);
  const { userList, userTotal } = useSelector((state) => state.reports);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [Options, setOptions] = useState([]);
  const [toDate, settoDate] = useState("");
  const [selecteddata, setselecteddata] = useState([]);
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    dispatch(gamesActions.getAllGames(gameReq2));
    dispatch(reportActions.getWinningList(gameReq));
    dispatch(gamesActions.getAllMarket());
    dispatch(gamesActions.getAllUser());
  }, []);

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id,
      }));
      // console.log(options);
      setOptions(options);
    }
  }, [allUserList]);

  // console.log(Options);
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit2 = () => {
    setFieldsGame({});
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(reportActions.getWinningList(gameReq));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    // }
    console.log(fieldsGame);
    let gameReq2 = {
      fromDate: fieldsGame?.date || "",
      // "toDate": fieldsGame?.todate || "",
      keyWord: fieldsGame?.keyWord || "",
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
    };
    dispatch(reportActions.getWinningList(gameReq2));
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select Game Name";
    }

    if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
      formIsValid = false;
      errors.userId2 = "Select Game Type";
    }

    // if (!fieldsGame.todate || fieldsGame.todate === "") {
    //   formIsValid = false;
    //   errors.todate = "Select Date";
    // }

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Select Date";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };

    dispatch(reportActions.getWinningList(pageReq));
  };

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    let pageReq = {
      type: "WITHDRAW",
      keyWord: value,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: 100,
    };
    // dispatch(walletActions.getPaymentListForAdmin(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    let pageReq = {
      // "type": "WITHDRAW",
      keyWord: keyWord,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: value,
    };
    dispatch(reportActions.getWinningList(pageReq));
  };
  const [selectedValues, setSelectedValues] = useState([]);

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative z-20 flex min-h-screen">
          <div className="flex w-full flex-col flex-1 overflow-hidden">
            <main className="relative flex-1 ">
              <div className="p-3 2xl:p-10 sm:p-5">
                <div className="mx-auto max-w-screen-3xl space-y-5">
                  <div className="text-left bg-white rounded-lg">
                    <div className="px-6 py-4 flex items-center justify-between border-b">
                      <span className="text-lg font-bold">
                        Winner List
                      </span>
                    </div>
                    <div className="px-6 py-2 w-full">
                      <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
                        <div className="w-full">
                          <label class="block text-gray-700 text-xs font-semibold mb-1" for="gamename">Date :</label>
                          <input
                            className="w-full px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none"
                            id="date"
                            name="date"
                            placeholder="Enter date"
                            value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""}
                            max={today}
                            type="date"
                            onChange={inputChangeCreate}
                          />
                          {errorsGame && errorsGame["date"] ? (
                            <div className="text-red-600 invalid-feedback">
                              {errorsGame["date"]}
                            </div>
                          ) : null}
                        </div>

                        <div className="w-full">
                          <label class="block text-gray-700 text-xs font-semibold mb-1" for="name">Game Name :</label>
                          <select
                            className="w-full px-3 py-2 capitalize text-sm bg-white border border-gray-400 rounded focus:outline-none"
                            id="userId"
                            name="userId"
                            placeholder="name"
                            value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""}
                            type="text"
                            onChange={inputChangeCreate}
                          >
                            <option value="">Select Game Name</option>
                            {gamesList && gamesList.length > 0
                              ? gamesList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>  {element && element.name ? element.name : ""}</option>
                              ))
                              : null}
                          </select>
                          {errorsGame && errorsGame["userId"] ? (
                            <div className="text-red-600 invalid-feedback">
                              {errorsGame["userId"]}
                            </div>
                          ) : null}
                        </div>

                        <div className="w-full flex items-center space-x-4">
                          <div className="w-full pt-4">
                            <button className="flex-1 justify-center w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bgcolor rounded-md  hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                          <div className="w-full pt-4">
                            <button className="flex-1 justify-center w-full px-2 whitespace-nowrap py-2 text-sm text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600" type="button" onClick={createGameSubmit2}>Reset</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-left rounded-lg bg-white">
                    {/* <div className="flex justify-between items-center  border-b px-4">
                      <div className="flex justify-start place-items-center gap-3">
                        <label class="block mb-2 text-sm font-medium text-gray-900" for="name">Data</label>
                        <select
                          className={`w-full px-3 py-1.5 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                          id="size"
                          name="size"
                          placeholder="name"
                          value={
                            fieldsGame && fieldsGame["size"]
                              ? fieldsGame["size"]
                              : ""
                          }
                          type="text"
                          onChange={handleInputSize}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="relative overflow-x-auto">
                      <div className="flex justify-between items-center">
                        <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-900 capitalize bg-gray-50  ">
                            <tr>
                              <th className="px-4 py-2">#</th>
                              <th className="px-4 py-2 whitespace-nowrap">user name</th>
                              <th className="px-4 py-2">amount</th>
                              <th className="px-4 py-2">Bet Number</th>
                              <th className="px-4 py-2">Game Name</th>
                              <th className="px-4 py-2">Market Name</th>
                              <th className="px-4 py-2">Market Type</th>
                              <th className="px-4 py-2">Date</th>
                              <th className="px-4 py-2">Bet Status</th>
                              <th className="px-4 py-2">
                                Profit/Loss
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {winList && winList.length > 0
                              ? winList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white border-b dark:bg-gray-80 capitalize text-slate-700">
                                    <th className="px-4 py-3 font-medium whitespace-nowrap ">  {offset + index + 1}</th>
                                    <th className="px-4 py-3 font-medium text-[#992941] whitespace-nowrap texthover hover:underline cursor-pointer">
                                      <Link to={`/app/user/${element.userId?._id}`}>  {element && element.userId && element.userId.userName ? element.userId.userName : "-"}</Link>
                                    </th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap">{element && element.amount ? element.amount : "-"}</th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap"> {element && element.betNumber ? element.betNumber : "-"}</th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap">{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap">{element && element.marketId && element.marketId.name ? element.marketId.name : "-"}</th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap"> {element && element.isOpen && element.isOpen ? "Open" : "Close"}</th>
                                    <th className="px-4 py-3 font-medium whitespace-nowrap uppercase">{element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}</th>

                                    {element.betStatus == 0 && (
                                      <th className="px-4 text-yellow-700 py-3 font-medium whitespace-nowrap">Pending</th>
                                    )}
                                    {element.betStatus == 1 && (
                                      <th className="px-4 py-3 font-medium text-green-600 whitespace-nowrap">Win</th>
                                    )}
                                    {element.betStatus == 2 && (
                                      <th className="px-4 py-3 font-medium text-red-600 whitespace-nowrap">Loss</th>
                                    )}
                                    <td className="px-4 py-3 ">
                                      {element?.betStatus == 1 && (
                                        <div className="font-medium text-green-600">{element?.winAmount || "--"} </div>
                                      )}
                                      {element?.betStatus == 2 && (
                                        <div className="font-medium text-red-600">{element?.lossAmount || "--"}</div>
                                      )}
                                      {element?.betStatus == 0 && (
                                        <div className="font-medium text-yellow-700">--</div>
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                              : <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>No Data Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>}
                          </tbody>
                        </table>
                      </div>
                      {isMobile ? (
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {winTotal && winTotal > 10 ? (
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={winTotal / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                            />
                          ) : null}
                        </nav>
                      ) : (
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {winTotal && winTotal > 10 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={winTotal / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                            />
                          ) : null}
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinList;
