import {
  karachiGamesConstants
} from '../_constants';
import KarachiPlaceBet from '../pages/KarachiPlaceBet/KarachiPlaceBet';

export default function karachiGame(state = {}, action) {

  console.log('action.games', action.games);
  switch (action.type) {


    case karachiGamesConstants.CREATE_KARACHI_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.CREATE_KARACHI_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiGamesConstants.CREATE_KARACHI_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case karachiGamesConstants.GET_ALL_KARACHI_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.GET_ALL_KARACHI_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiGamesList: action.games.data.list,
        karachiGamesTotal: action.games.data.total,
        loading: false,
      };
    case karachiGamesConstants.GET_ALL_KARACHI_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiPlaceBet: action.games.data,
        // karachiGamesTotal: action.games.data.total,
        loading: false,
      };
    case karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case karachiGamesConstants.UPDATE_KARACHI_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.UPDATE_KARACHI_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiGamesConstants.UPDATE_KARACHI_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case karachiGamesConstants.GET_KARACHI_GAMES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case karachiGamesConstants.GET_KARACHI_GAMES_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiGamesList: action.games.data.list,
        karachiGamesTotal: action.games.data.total,
        loading: false,
      };
    case karachiGamesConstants.GET_KARACHI_GAMES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}